.error {
  color: #f44336 !important;
  font-size: 12px;
}
.border-error {
  background-color: #ff859026;
}
.cfc-data {
  position: relative;
  padding-left: 200px !important;
  margin-top: 20px !important;
  .cfc-view {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 40px;
    .cfc-label {
      font-size: 12px;
      color: #8996af;
    }
    .cfc-value {
      font-size: 16px;
      font-weight: bold;
      color: #1c1d21;
      margin-bottom: 20px;
    }
  }
  .wtt {
    top: 22px;
    left: 110px;
  }
  .ttw {
    top: 64px;
    left: 110px;
  }
}
.cfc-total {
  margin-left: 14px !important;
  .cfc-view {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 40px;
    .cfc-label {
      font-size: 12px;
      color: #8996af;
      opacity: 0;
    }
    .cfc-value {
      font-size: 16px;
      font-weight: bold;
      color: #1c1d21;
      margin-bottom: 20px;
    }
  }
}
.cfc-transport {
  margin-left: 96px;
  display: flex;
  min-width: 1400px;
  border-bottom: 1px solid #8996af34;
  border-top: 1px solid #8996af34;
  padding: 17px 0;
  .cfc-transport-name {
    color: #8996af;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
  }
  .cfc-transport-mode {
    margin-left: 20px;
  }
}
.cfc-table-total {
  width: 100%;
  display: flex;
  height: 70px;
  align-items: center;
  .cfc-table-total-label {
    background-color: #8996af14;
    color: #8996af;
    font-weight: bold;
    font-size: 16px;
    border-radius: 0 0 0 10px;
    width: 214px;
    height: 100%;
    text-align: center;
    padding-top: 28px;
  }
  .cfc-label {
    visibility: none;
  }
}

.cfc-location {
  font-weight: bold !important;
  font-size: 16px;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cfc-location::first-letter {
  text-transform: uppercase;
}

.cfc-code {
  font-weight: bold;
  font-size: 10px;
  background: #fafbfc;
  border: 1px solid #eeeeee;
  border-radius: 12px;
}

.cfc-transport-label {
  height: 33px;
  background: #8896af14;
  opacity: 1;
  .cfc-transport-via-mode {
    font-weight: bold;
    font-size: 12px;
    padding-top: 9px;
    color: #8996af;
    padding-left: 28px;
  }
}

.dp-showLocation {
  font-size: x-small;
  opacity: 0.3;
}

.dp-showLocation::first-letter {
  text-transform: uppercase;
}
.cfc-chip {
  padding: 4px;
  background-color: #fafbfc;
  border-radius: 12px;
  margin-right: 10px;
  font-size: 10px;
  font-weight: bold;
  border: 1px solid #eeeeee;
}
.cfc-view-option {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cfc-view-box {
  position: relative;
  .view-flag {
    position: absolute;
    left: 10px;
    top: 40%;
  }
  .view-detail {
    position: absolute;
    right: 20px;
    top: 30%;
  }
  .MuiFilledInput-root,
  .MuiFilledInput-root:hover {
    background-color: transparent;
  }
  .MuiFilledInput-underline {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 6px;
  }
  .Mui-error {
    border-color: #f44336;
  }
  .MuiFilledInput-underline::before,
  .MuiFilledInput-underline::after {
    content: none;
  }
}
#cfc-country {
  padding-left: 30px;
  width: 100%;
}
.cfc-best {
  color: #007a33;
}
.cfc-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cfc-flag {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.cfc-drop-icon {
  width: 25px;
  height: 25px;
  background: #f5f5fa;
  border-radius: 8px;
}

.cfc-accordion {
  border-radius: 15px !important;
}
.cfc-background-best {
  background-color: #007a330d !important;
}
.cfc-chart {
  border: 1px solid #8996af34;
  border-radius: 14px;
  margin-bottom: 24px;
}

.cfc-card {
  .c-title {
    height: 66px;
    border-radius: 14px 14px 0 0;
    background-color: #8996af0f;
    flex-direction: column;
    padding: 14px 24px;
    .c-label {
      color: #8996af;
      font-size: 11px;
    }
  }
}
.cfc-chart-legend {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.cfc-chart-legend-box {
  background: #8996af0f;
  border-radius: 12px 1px 0px 12px;
  height: 50px;
  width: 214px;
}

.cfc-label-chart {
  transform: matrix(0, -1, 1, 0, 0, 0);
  color: #8996af;
  text-align: left;
  width: 400px;
  font-size: 12px;
  left: -210px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  bottom: 100%;
}

.cfc-list-chart-style {
  width: 152px;
  height: 46px;
  background: #ffffff;
  border-radius: 8px;
  font-weight: bold !important;
  color: #1c1d21;
}
.MuiAccordion-root:before {
  display: none !important;
}
.cfc-normal {
  color: #5e81f4;
}
.cfc-best-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #007a3312;
  color: #007a33;
  justify-content: center;
  padding-left: 5px;
}

.cfc-cargo-weight {
  font-size: 10px;
  border: 1px solid #eeeeee;
  width: 40px;
  text-align: center;
  border-radius: 12px;
  position: absolute;
  right: 10%;
  top: 35%;
  color: #49494b;
}

.cfc-word-box {
  align-items: center;
  justify-content: center;
  background-color: #8996af1a;
  width: 100%;
  height: 56px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.cfc-icon-box {
  display: flex;
  align-items: center;
  background-color: #ebeffb;
  width: 100%;
  height: 56px;
  border-radius: 11px;
  justify-content: center;
}

.cfc-cargo-infomation {
  height: 178px;
  background-color: #fafbfc;
}

.cfc-export-button {
  height: 40px !important;
  border: 1px solid #ececf2 !important;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  color: #8996af !important;
}
.cfc-search-des {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cfc-calculator {
  background-color: #ffffff;
  padding-bottom: 80px;
  position: relative;
  .cfc-show-more {
    border-radius: 0 0 11px 11px;
    background-color: #5e81f40f;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 9px 16px;
    .title {
      font-size: 14px;
      font-weight: 600;
      color: #5e81f4;
    }
    .label {
      font-size: 9px;
      color: #8996af;
      display: none;
    }
    &:hover {
      cursor: pointer;
      .label {
        display: block;
      }
    }
    .expand-icon {
      background: #ffffff;
      border: 1px solid #cdd2dd;
      border-radius: 6px;
    }
  }
}

.cfc-result {
  left: -7%;
  position: relative;
}
.cfc-main-transport-icon {
  color: #5e81f4;
  width: 36px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  position: absolute;
  left: 53%;
}
.cfc-code-div {
  width: 80px;
  display: flex;
  justify-content: center;
}
.cfc-input-number {
  position: relative;
  width: 100%;
  .MuiFilledInput-root,
  .MuiFilledInput-root:hover,
  .MuiFilledInput-root:focus {
    background-color: transparent;
  }
  .MuiFilledInput-underline {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 6px;
  }
  .Mui-error {
    border-color: #f44336;
  }
  .MuiFilledInput-underline::before,
  .MuiFilledInput-underline::after {
    content: none;
  }
}
.cfc-select {
  position: relative;
  .MuiFormLabel-filled {
    top: 4px;
  }
  .MuiOutlinedInput-input {
    display: flex;
    justify-content: space-between;
  }
}
.cfc-advance-mode {
  border-radius: 14px;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #8996af34;
  padding: 8px;
}
.cfc-calculate-advan {
  .grid-bottom {
    z-index: 900;
    position: sticky;
    position: -webkit-sticky;
    bottom: 50px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .MuiFormControlLabel-root {
    margin: 0;
  }
  .cfc-checkbox {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    border: 1px solid #e4ebf6a2;
    height: 56px;
    background-color: #ffffff;
    border-radius: 12px;
    position: relative;
    flex-direction: row;
    .MuiCheckbox-root {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      left: 0;
    }
    .Mui-checked {
      background-color: #8996af14;
    }
    .MuiFormControlLabel-label {
      position: absolute;
      left: 16px;
    }
  }
  .MuiFilledInput-root,
  .MuiFilledInput-root:hover,
  .MuiFilledInput-root:focus {
    background-color: transparent;
  }
  .MuiFilledInput-underline {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 6px;
  }
  .Mui-error {
    border-color: #f44336;
  }
  .MuiFilledInput-underline::before,
  .MuiFilledInput-underline::after {
    content: none;
  }
  .via-component {
    margin-top: 16px;
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #e4ebf6a2;
  }
  .via-add {
    width: 100%;
    height: 54px;
    border-radius: 8px;
    background-color: #8996af10;
    color: #8996af;
    font-size: 14px;
    padding: 9px 10px;
    cursor: pointer;
    span {
      flex-direction: column;
    }
    .show-detail {
      display: none;
      font-size: 11px;
      line-height: 30px;
    }
    .add-leg {
      line-height: 35px;
    }
    .icon-add {
      width: 36px;
      height: 36px;
      border: 1px solid #ececf2;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    &:hover {
      background-color: #5e81f410;
      .show-detail {
        display: block;
      }
      .add-leg {
        line-height: 10px;
      }
      .icon-add {
        border-color: #5e81f4;
        color: #5e81f4;
      }
    }
  }
  .cfc-percent {
    padding: 4px 8px;
    background-color: #fafbfc;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 13px;
    font-weight: bold;
    border: 1px solid #eeeeee;
  }
}

.radio-component {
  position: relative;
  height: 56px;
  min-width: 212px;
  width: 100%;
  padding: 16px 22px;
  border-radius: 12px;
  background-color: #ffffff;
  border: 1px solid #e4ebf6a2;
  .Mui-checked {
    background-color: #8996af14;
    border-radius: 12px;
  }
  .MuiRadio-root {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
  }
}
.avatar-component {
  .avatar {
    color: #5e81f4 !important;
  }
  .MuiAvatarGroup-avatar {
    background-color: white;
    border: 1px solid #eeeeee;
  }
}
