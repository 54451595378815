/** 
 * @moduleName: data loader
 * @createdDate: 08-aug-2020
 * @author: senthilkumar-r
 * @copyright: GeTS
 */

/* 
 * This stylesheet has data loader style decalrations
 */

@charset "utf-8";

@import './helpers';

body {
  &::after {
    transition: background-color 0.3s ease-in-out;
  }

  &.please-wait {
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      width: 1rem;
      height: 1rem;
      vertical-align: text-bottom;
      border: 0.25em solid $primary-blue;
      border-right: 0.25em solid transparent;
      border-radius: 50%;
      animation: spinner-border 0.75s linear infinite;
      position: absolute;
      left: calc(50% - 1em);
      top: calc(50% - 1em);
      z-index: 99999;
    }

    $spinnerSizes: 20px, 36px, 48px;

    @each $size in $spinnerSizes {
      &.spinner-#{$size} {
        width: $size !important;
        height: $size !important;
        left: calc(50% - #{$size}) !important;
        top: calc(50% - #{$size}) !important;
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      display: flex;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9999;
    }
  }
}

/* [Spinner] --- Starts Here */
.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
  100% {
    transform: rotate(1turn);
  }
}
/* [Spinner] --- Ends Here */
