/** 
 * @moduleName: application theme
 * @createdDate: 08-aug-2020
 * @author: senthilkumar-r
 * @copyright: GeTS
 */

/* 
 * This stylesheet has SaSS variables to centralize the changes
 */

 @charset "utf-8";

 /* [Color Palettes, as per style guide] */
 //Primary
 $primary-blue: #5e81f4;
 $primary-blue-hover: #506cc9;
 $primary-dark: #1c1d21;
 $primary-gray: #8996af;
 $primary-blueish-gray: #8996af; //rgba(137, 150, 175, 1);
 $gray: #eeeeee; //Used for components
 $gray-overlay: #1c1d2133; //Overlay gray
 $light-gray-outline: #f0f0f3; //Outline gray
 $light-gray-bg: #f6f6f6; //Background gray
 $light-blue: #f5f5fa; //Background light blue
 $white: rgba(255, 255, 255, 1); //ffffff;
 $black: rgba(0, 0, 0, 1); //000000;
 
 // Secondary
 $secondary-orange: #f4be5e;
 $secondary-green: #7ce7ac;
 $secondary-red: #ff808b;
 $secondary-aqua: #40e1fa;
 $secondary-violet: #9698d6;
 
 // Outline
 $outline-primary-blue: $primary-blue;
 $outline-secondary-orange: $secondary-orange;
 $outline-secondary-green: $secondary-green;
 $outline-secondary-red: $secondary-red;
 $outline-secondary-aqua: $secondary-aqua;
 $outline-secondary-violet: $secondary-violet;
 $outline-resting-gray: #ececf2;
 $outline-white: #ffffff;
 
 // Primary and Secondary color palettes opacity patterns
 $primary-azure: #5e81f41a;
 $primary-azure-hover: #1c1d211a;
 $secondary-aqua-light: #40e1fa1a;
 $secondary-green-light: #7ce7ac1a;
 $secondary-yellow-light: #f4be5e1a;
 $secondary-red-light: #ff808b1a;
 
 $sidebar-nav-active-bg: #5e81f419;
 $space-black: #1c1d21;
 $error-border-red: rgb(255, 128, 139); //#FF808B;
 $pinkred: rgb(255, 128, 139); //#FF808B;
 $error-bg-red: #ff808b05;
 $elem-border-color: #ececf2;
 
 // Form Controls or Component states
 $btn-primary-state-normal: #5e81f4; //rgba(94, 129, 244, 1);
 $btn-primary-state-hover: #506cc9; //rgba(80, 108, 201, 1);
 $btn-primary-state-focus: #5e81f4c7;
 $btn-primary-state-active: #5e81f4; //rgba(94, 129, 244, 1);
 $btn-primary-text: #ffffff;
 
 $btn-secondary-state-normal: #5e81f419; //rgba(94, 129, 244, 0.1);
 $btn-secondary-state-hover: #5e81f434;
 $btn-secondary-state-focus: #5e81f452;
 $btn-secondary-state-active: #5e81f419;
 $btn-secondary-text: #5e81f4;
 
 $btn-gray-normal-fill: #f5f5fa;
 $btn-gray-hover-fill: #eeeeee;
 $btn-gray-active-fill: #5e81f419;
 
 $btn-outline-state-normal-fill: #ffffff;
 $btn-outline-state-normal-border: #ececf2;
 $btn-outline-state-hover-fill: #8996af0f;
 $btn-outline-state-hover-border: #ececf2;
 $btn-outline-state-focus-fill: #5e81f419;
 $btn-outline-state-focus-border: #ececf2;
 $btn-outline-state-active-fill: #ffffff;
 $btn-outline-state-active-border: #ececf2;
 $btn-outline-state-disabled-fill: #ffffff;
 $btn-outline-state-disabled-border: #f6f6f6;
 $btn-outline-text: #5e81f4;
 
 $textbox-radius: 4;
 $textbox-border-size: 2;
 $textbox-border-color: #eeeeee;
 $textbox-normal-bg: #5e81f405;
 $textbox-focus-bg: #5e81f40a;
 $textbox-active-border-color: rgb(94, 129, 244); //#5e81f4
 
$label-primary-color: rgba(28, 29, 33, 0.8);
$label-secondary-color: rgb(137, 150, 175); //#8996AF;
 $label-hover-color: #1c1d21cb;
 $label-normal-color: #1c1d2133;
 
 $checkbox-label-color: #1c1d21cc;
 $checkbox-selected-color: $primary-blue;
 
 $select-border-color: $elem-border-color;
 $select-focus-bg: #5e81f40a;
 
 $btn-useraccount-focus-bg: #5e81f40a;
 $btn-useraccount-active-border-color: rgb(94, 129, 244); //#5e81f4
 
 $circular-bgn-active-border-color: rgb(94, 129, 244); //#5e81f4
 
 $popover-list-hover-bg: #1c1d210f;
 $avatar-border-color: $elem-border-color;
 $avatar-icon-fill: #8996af;
 
 $popover-list-primary-title-color: #1c1d21;
 $popover-list-secondary-title-color: #8996af;
 
 $tab-state-normal-fill: #ffffff; //rgba(255, 255, 255, 1);
 $tab-state-hover-fill: #8996af10; //rgba(137, 150, 175, 0.06);
 $tab-scroll-btn-normal-fill: #8996af10; //rgba(137, 150, 175, 0.06);
 $tab-scroll-btn-hover-fill: #5f6b8118; //rgba(95, 107, 129, 0.093);
 $tab-state-focus-border: #ececf2; //rgba(236, 236, 242, 1);
 
 $toggle-track-off-bg: #eeeeee;
 $toggle-track-on-bg: $primary-blue;
 $toggle-thumb-border: #1c1d2133;
 
 $snackbar-success: #7ce7ac;
 $snackbar-error: #ff808b;
 $snackbar-info: #8996af;
 $snackbar-warning: #f4be5e;
 
 $chip-outline-border: #5e81f480;
 $chip-outline-hover-fill: #f5f5fa;
 $chip-outline-focus-fill: #f0f0f3;
 $chip-filled-fill: $primary-azure;
 $chip-filled-hover-fill: #1c1d210f;
 
 $chip-outline-disabled: #1c1d2133;
 $chip-disabled-text: #1c1d2133;
 $chip-filled-disabled-fill: #f6f6f6;
 
 $linear-progress-track: #f6f6f6;
 
 $slider-rail-fill: #5e81f419;
 $slider-track-fill: #5e81f4;
 $slider-thumb-fill: #7ce7ac;
 $slider-tooltip-fill: #eeeeee;
 $slider-tooltip-text: #252631;
 $slider-mark-fill: #8996af80;
 $slider-mark-active-fill: #ffffffa3;
 $slider-rail-disabled-fill: #eeeeee;
 $slider-track-disabled-fill: #1c1d2133;
 $slider-thumb-disabled-fill: #626263;
 $slider-mark-disabled-fill: #1c1d210f; //#e9e9e933;
 
 $select-list-fill: #fafbfc;
 $select-list-item-hover-fill: #5e81f419;
 $select-list-item-selected-fill: #1c1d210f;
 
 $datepicker-navbtn-normal-fill: #f5f5fa;
 $datepicker-navbtn-hover-fill: #e6e6e6;
 
 $dropzone-control-border: #ececf2;
 $dropzone-hover-fill: #5e81f419;
 $dropzone-hover-border: #5e81f4;
 $dropzone-helpertext: #8996af;
 $dropzone-list-separator: #1c1d210f;
 $dropzone-file-icon-fill: #8996af10;
 $dropzone-file-name: $primary-dark;
 $dropzone-file-size: $dropzone-helpertext;
 
 $tile-border: #f5f5fa;
 $tile-shadow: rgba(0, 0, 0, 0.05);
 $tile-header: $primary-dark;
 $tile-description: $gray-overlay;
 
 $toggle-btn-selected-border: #ececf2;
 $toggle-btn-selected-fill: #ffffff;
 $toggle-btn-selected-text: #1c1d21;
 $toggle-btn-normal-text: #8996af;
 $toggle-btn-hover-fill: #e7e7e7;
 
 $popover-menu-fill: #ffffff;
 $popover-list-item-fill: #ffffff;
 $popover-list-item-hover-fill: rgba(137, 150, 175, 0.06);
 $popover-list-item-selected-fill: rgba(137, 150, 175, 0.06);
 
 $mui-table-header-fill: #f5f5fa65;
 $mui-table-row-hover-fill: #8996af10;
 $mui-table-row-border-fill: #e4ebf6a2;
 $mui-table-cell-text: #1c1d21cc;
 $mui-table-th-hover-fill: #8996af10;
 $mui-table-th-text: #1c1d21;
 $mui-table-page-btn-fill: #f5f5fa;
 $mui-table-page-btn-active-fill: $primary-blue;
 $mui-table-page-navbtn-fill: #f5f5fa;
 $mui-table-page-navbtn-hover-fill: #ececec;
 $mui-table-page-btn-disabled-fill: #f5f5fa80;
 