/** 
 * @reason: Browser agent style rendering black outline when focus
 * @fix: Resetting the browser default behavior
 */
button {
  &:focus {
    outline: none !important;
  }
}

label {
  margin-bottom: 0 !important;
  margin-block-end: 0;
}
/* Reset fix --- Ends here */

.page-content {
  background-color: #fcfcfc;
}

.page-content-groupLabel {
  color: #8e98b0 !important;
  font-weight: bold !important;
}
.boldLabel {
  color: black !important;
  font-weight: bold !important;
  font-size: 20;
}
.greyLabel {
  color: gray !important;
}
.greyLabel2 {
  color: #8e98b0 !important;
}
.titleBox {
  color: '#cfd8dc' !important;
  padding: 5px 5px 5px 10px;
}
.alignRight {
  text-align: right;
}
.paddingRight10 {
  padding-right: 10px;
}
.carrierAvatar {
  width: 50px;
  height: 50px;
}
.moreMenuBtn {
  //padding: 2px;
  background-color: whitesmoke !important;
  border-radius: 20% !important;
  width: 35px;
  height: 35px;
}

.rs-countryImageRight {
  height: 25px;
  padding-right: 5px;
  padding-top: 2px;
  width: 40px;
}
.hoverCarrierName {
  background: #1c1d21b7 0% 0% no-repeat padding-box;
  opacity: 1;
  height: auto;
  padding: 10px;
  display: inline-block;
  position: relative;
  top: -40px;
  left: 20px;
  z-index: 9999;
  border-radius: 4px;
  color: #fcfcfc;
}
.hoverCntrISO {
  background: #1c1d21b7 0% 0% no-repeat padding-box;
  opacity: 1;
  height: auto;
  padding: 10px;
  display: inline-block;
  position: relative;
  top: -60px;
  left: 100px;
  z-index: 9999;
  border-radius: 4px;
  color: #fcfcfc;
}
.greyBox {
  background-color: whitesmoke !important;
  border-radius: 15px !important;
  padding: 20px;
}
.inner-card-group {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.inner-card-background {
  background-color: #fcfdff;
  // background-color: lightgreen;
}

.accordion-footer {
  justify-content: space-between !important;
  text-transform: none !important;
  background-color: #f4f4fc !important;
  color: #8e98b0 !important;

  &-add-component {
    @extend .accordion-footer;
    justify-content: flex-start !important;
  }
}

.light-green {
  color: #7fe5ae !important;
}

.redText {
  color: red !important;
}

.originalText {
  text-transform: none !important;
}

.button-light-green {
  background-color: #7fe5ae !important;
  color: white !important;
}

.button-light-green:hover {
  background-color: #69bf91 !important;
}

.button-blue {
  background-color: #5e81f4 !important;
  color: white !important;
}
.button-blue2 {
  background-color: #c4cde2 !important;
  color: #5e81f4 !important;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.list-item-p10 {
  padding: 10px;
}

.review-header {
  padding-top: 5px;
  padding-bottom: 5px;
}

.view-card {
  padding-left: 16px;
  padding-right: 16px;

  &-header {
    @extend .view-card;
    font-size: large;
    font-weight: bolder;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: #f4f4fc !important;
  }
  &-header-no-bg {
    @extend .view-card;
    font-size: large;
    font-weight: bolder;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &-header-info {
    font-size: small;
    color: #abb9da;
    text-transform: uppercase;
  }

  &-content {
    padding-top: 16px;
    padding-bottom: 16px;

    &-label {
      @extend .view-card;
      font-size: 12px;
      color: #8e98b0;
    }

    &-value {
      @extend .view-card;
      font-size: medium;
      font-weight: bolder;
      padding-top: 5px;
    }
  }
}

.schedule-info-row-header {
  padding: 10px 10px 10px 10px;
  color: #abb9da;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  background-color: #f4f4fc !important;
}

.schedule-info-row {
  padding: 10px 10px 10px 10px;
  font-size: 13px !important;
  font-weight: 600 !important;

  &-pb {
    @extend .schedule-info-row;
    padding: 10px 10px 40px 10px;
  }
}

.center-line {
  width: 100%;
  // border-bottom: 3px solid rgb(179, 176, 176);
  border-bottom: 5px solid rgba(0, 0, 0, 0.075);
  line-height: 0.1em;
  margin: 10px 0 20px;
  z-index: 1;
}

.center-line-transport {
  width: 100%;
  // border-bottom: 3px solid rgb(179, 176, 176);
  border-bottom: 3px solid rgba(0, 0, 0, 0.075);
  line-height: 0.1em;
  z-index: 1;
}

.port-connector-left {
  // margin: -16px 80px -40px 24px;
  padding-left: calc(5% + 20px) !important;
}

.port-connector-right {
  padding-right: calc(100% - 100px);
}

.icon-circle-border {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 0.1em 0.1em;
}

.schedule-info-row-bg {
  background-color: #fcfcfc !important;
}

.schedule-carrier-voyage-service {
  font-size: 12px;
  color: #8e98b0;
}

.no-padding {
  padding: 0px !important;
}

// .circle-transport-icon {
//   display: block;
//   height: 30px;
//   width: 30px;
//   border-radius: 50%;
//   background-color: #eaeaf0;
// }

.view-card-item-content {
  padding-top: 10px;
  padding-bottom: 10px;
}

.info-label {
  font-size: 14px;
  color: #8e98b0;
}

.avatar {
  color: white !important;
}

.border-light-blue {
  border-color: rgb(100, 181, 246) !important;
}

.bold-large-text {
  font-size: large;
  font-weight: bolder;
}

.text-upper {
  text-transform: uppercase;
}

.view-field-img {
  height: 25px !important;
  padding-right: 5px !important;
  padding-top: 2px !important;
  width: 40px !important;
}

.view-field-img-lg {
  height: 50px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  width: 85px !important;
}

.accordian-header {
  background-color: #f4f4fc !important;
}

.accordian-padding {
  padding: 16px;
}
.paddingLeft16 {
  padding-left: 16px;
}
.paddingTop16 {
  padding-top: 16px;
}
.ng-button-filled-secondary2 {
  background-color: #5e81f419 !important;
  border-radius: 15px !important;
  padding: 5px 8px;
  border-color: #5e81f419 !important;
  box-shadow: 0 0 1px 0 rgba(94, 129, 244, 0.1),
    0 2px 2px -2px rgba(94, 129, 244, 0.25) !important;
  color: #5e81f4 !important;
  text-transform: none !important;
}
.white-chip {
  background-color: white !important;
  color: #8e98b0 !important;
  border: 2px solid #dedef7 !important;
  font-weight: bolder;
}
.danger-chip {
  background-color: white !important;
  color: #fe979e !important;
  border: 2px solid #dedef7 !important;
  font-weight: bolder;
}
.primary-chip {
  background-color: white !important;
  color: #7d8ff4 !important;
  border: 2px solid #dedef7 !important;
  font-weight: bolder;
}
.success-chip {
  background-color: white !important;
  color: #84e5a8 !important;
  border: 2px solid #dedef7 !important;
  font-weight: bolder;
}
.info-chip {
  background-color: white !important;
  color: #9391b4 !important;
  border: 2px solid #dedef7 !important;
  font-weight: bolder;
}
.rightConnerBox {
  position: relative;
  top: 5px;
  right: 5px;
}
.highLighted {
  background: #f4be5e0f 0% 0% no-repeat padding-box;
}
.blackDot {
  height: 4px;
  width: 4px;
  margin: 0 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}

.warningBox {
  background-color: #fffbf5;
  border-color: #fef4e2;
}

.warningText {
  color: #49494b;
}
.ml-28 {
  margin-left: 28px;
}
.pl-28 {
  padding-left: 28px;
}
.bold {
  font-weight: bold;
}
.fl-e {
  justify-content: flex-end;
}
.fl {
  display: flex;
}
.fl-r {
  flex-direction: row !important;
}
.fl-c {
  align-items: center;
}
.bd-b {
  border-bottom: 1px solid #8996af34;
}
.mr-10 {
  margin-right: 10px;
}
.text-c {
  text-align: center;
}
.pl-20 {
  padding-left: 20px;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}
.bord {
  border: 1px solid #8996af34;
}
.fw-600 {
  font-weight: 600;
}

.fw-600::first-letter {
  text-transform: uppercase;
}
.mt-20 {
  margin-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.bg-w {
  background-color: #ffffff;
}
.p-20 {
  padding: 20px !important;
}
.fl-sb {
  justify-content: space-between;
}
.full-w {
  width: 100%;
}
.al-c {
  align-items: center;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.text-l {
  text-align: left;
}
.mr-70 {
  margin-right: 70px;
}
.mr-30 {
  margin-right: 30px;
}
