.ping-event{
    .list-node{
        list-style-type: auto;
        padding-left: 16px;
    }
    .title-label{
        font-size: 16px;
        font-weight: bold;
        &.node{
            text-decoration: underline;
        }
    }
    .btn-ping{
        font-weight: bold;
    }
    .btn-primary{
        background-color: var(--blue-link-color);
        color: var(--white);
    }
    .label-input{
        color: var(--blue-link-color);
        display: flex;
        align-items: center;
    }
   
 .btn-title{
     background-color: #8996af1a;
     width: 100%;
     color: #8996af;
     font-weight: bold;
     font-size: 16px;
     height: 56px;
     align-items: center;
     display: flex;
     flex-direction: column;
     justify-content: center;
     border-radius: 12px;
     .label{
         font-size: 11px;
     }
 }
 .btn-add{
     display: flex;
     justify-content: flex-end;
     align-items: flex-end;
     .btn-primary{
         width: 230px;
         height: 42px;
     }
 }
 .event-subscription{
     position: relative;
     .vs-noresult{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #0000001c;
        top: 0;
    left: 0;
    z-index: 100;
    }
 }
 .event-subscription,.event-tabel{
     background-color: #ffffff;
     border-radius: 16px;
     border: 1px solid #e4ebf6a3;
 }
 .event-tabel{
     margin-top: 20px;
     position: relative;
 }
 
 .box-view{
     position: relative;
     input{
         padding-left: 24px !important;
     }
    .MuiFilledInput-root{
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
    }
    .MuiFilledInput-underline::before, .MuiFilledInput-underline::after{
        content: none;
    }
    .Mui-error{
        border-color: #f44336;
     }
     img{
         width: 20px;
         height: 20px;
         border-radius: 50%;
     }
     .hide-img{
         display: none;
     }
   
 }
 .error{
    color: #f44336;
 }
 .no-item{
    height: 42px;
 }
 .btn-tabel, .btn-unsubcribe{
     width: 142px;
     height: 42px;
     border-radius: 8px;
     text-align: center;
     line-height: 42px;
     cursor: pointer;
     background-color: #5e81f41a;
     color:#5e81f4;
     font-weight: 600; 
 }
 .btn-unsubcribe{
     color: rgba(0, 0, 0, 0.23);
 }
 .row-btn{
     display: flex;
     justify-content: flex-end;
     padding-right: 14px;
 }
 .input-search{
     text-align: end;
     cursor: pointer;
 }
 .ref-num{
    width: 350px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
     .chip-no{
        display: flex;
        align-items: center;
     }
 .chip{
     padding:  4px 13px;
     font-size: 14px;
     text-transform: uppercase;
     color: #5e81f4;
     background-color: #ffffff;
     border-radius: 12px;
     border: 1px solid #e4ebf6a2;
     font-weight: bold;


 }
 .chip-text{
    font-size: 14px;
    text-transform: uppercase;
    color: #8996af;
    margin-left: 4px;
    font-weight: bold;
 }
 .left{
    margin-left: 30px;
    }
    }
    .tabel-text{
        color: #1c1d21;
        font-size: 14px;
        font-weight: 700;
    }
}
.tabel-component{
    position: relative;
    .vs-noresult{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #0000001c;
        top: 0;
    left: 0;
    z-index: 100;

    }
    .head-tabel{
        display: flex;
        flex-direction: column;
    }
}
.cng-select {
.MuiFilledInput-root,
.MuiFilledInput-root:hover
{
    background-color: rgba(94, 129, 244, 0.019);
    border:  1px solid rgba(0, 0, 0, 0.23)  ;
    
  }
.MuiSelect-select:focus {
  background-color: rgba(94, 129, 244, 0.019);
  border:  2px #5E81F4 solid !important  ;
  border-radius: 6px;
}
.MuiFilledInput-underline {
  border: 1px #EEEEEE solid;
  border-radius: 6px;
}
.Mui-error {
  border-color: #FF808B !important;
}
.MuiFilledInput-underline::before,
.MuiFilledInput-underline::after {
  content: none ;
}
.MuiInputLabel-shrink {
    transform: translate(0px, 10px) scale(0.75);
}
.MuiFormHelperText-root.Mui-error {
    color: #FF808B;
    margin: 3px 14px 0;
}
}
