/** 
 * @moduleName: Sub-pages
 * @createdDate: 24-dec-2020
 * @author: senthilkumar-r
 * @copyright: GeTS
 */

/* 
 * This stylesheet has inner pages style decalrations
 */

@charset "utf-8";

@import './skin';
@import './_helpers';

.ng-content-container {
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}

.ng-innerpage-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: '100%';
  align-items: 'center';

  // Section Hero Banner
  .ng-section-hero-banner {
    width: 100%;
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background-image: linear-gradient(
        0deg,
        rgba(64, 225, 250, 0.8),
        rgba(124, 231, 172, 0.8)
      );
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }

    .hero-img {
      width: 100%;
      max-width: 100%;
      height: 300px;
      object-fit: cover;
      position: relative;
    }

    .ng-banner-btn-placeholder {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 10;
      display: flex;
      width: auto;
    }
  }

  .ng-banner-btn-placeholder {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 10;
    display: flex;
    width: auto;
  }

  // Content container
  .ng-content-wrapper {
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    margin: 30px 20px;

    // Terms and Conditions Page Styles ---- Starts Here
    .section-display-content {
      margin-bottom: 45px;
      counter-reset: ngListNumericCounter;

      .section {
        &-title {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 5px;

          &.primary {
            font-size: 32px;
          }
        }
        &-para {
          font-size: 16px;
          font-weight: 300;
        }

        &-bulletin-list {
          font-size: 16px;
          margin: 20px 0px 20px 20px;
          padding: 0;
          list-style: none;

          li {
            counter-increment: ngListNumericCounter;
            margin: 20px 0 0 0;

            &::before {
              content: counter(ngListNumericCounter) '. ';
              font-weight: 700;
            }
          }

          ol.list-nested {
            margin: 0 0 0 30px;
            padding: 0;
            list-style: none;

            li {
              margin: 5px 0;

              &::before {
                content: '';
                width: 5px;
                height: 5px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 5px;
                margin-bottom: 3px;
              }
            }
          }
        }
      }
    }
    // Terms and Conditions Page Styles ---- Ends Here
  }
}
