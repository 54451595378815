/** 
 * @moduleName: login
 * @createdDate: 08-aug-2020
 * @author: senthilkumar-r
 * @copyright: GeTS
 */

/* 
 * This stylesheet has style declarations for login module
 */

 @charset "utf-8";

 @import './form';
 @import './helpers';
 @import './loginTheme';
 
 .ng-login-jumbotron-bg {
   background-color: rgba(0, 0, 0, 0.8);
   // background-image: url("../img/hero/login-jumbotron-bg.png");
   background-repeat: no-repeat;
   background-size: cover;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   // font-family: "Lato", sans-serif !important;
 }
 
 /* [Header Pane] --- Starts Here */
 .ng-login-form-header {
   height: 40px;
   display: flex;
   align-items: center;
 
   .btn-go-back {
     font-size: 14px;
     font-weight: 700;
     position: relative;
     display: flex;
     text-transform: none;
     transition: color 0.3s ease-in;
     font-family: inherit !important;
 
     &:hover {
       background-color: transparent !important;
 
       .label-icon {
         transform: translateX(-3px);
         transition: transform 0.3s ease-in;
       }
     }
   }
 }
 
 /* [Header Pane] --- Ends Here */
 
 /* [Login Card] --- Starts Here */
 .ng-login-card {
   overflow: visible !important;
   display: flex !important;
   position: relative !important;
   border-radius: 20px !important;
   padding: 10px 10px 10px 0px !important;
 
   & > * {
     flex-grow: 1;
     flex-basis: 50%;
     width: 50%;
   }
 }
 
 .ng-login-card-header {
   display: flex;
 
   .logo-gets {
     width: 45px;
     height: 60px;
     margin-bottom: 5px;
     margin-top: 0;
   }
 
   .card-item {
     display: inline-flex;
     justify-content: flex-end;
 
     &.card-titles {
       flex-direction: column;
     }
   }
 }
 
 .ng-login-card-content {
   padding: 32px 32px 24px 32px;
 }
 
 .ng-login-card-media {
   border-radius: 10px;
   // background-image: url("../img/hero/login-jumbotron-bg.png");
   // background-image: url("../img/hero/theme1-bg.png");
   background-repeat: no-repeat;
   background-position: center;
 }
 
 /* [Login Card] --- Ends Here */
 