/** 
 * @moduleName: An application specific styles
 * @createdDate: 21-Nov-2022
 * @author: senthilkumar-r
 * @copyright: GeTS
 */

/* 
 * This stylesheet has application specific global styles
 *  such as skeleton and bootstrap
 */

@charset "utf-8";

// Sass variables
$col-sizes: (1, 2, 3, 4, 5);
$max-font-size: 150;
$form-label-text-color-light: #8996af;
$form-value-text-color-light: #1c1d21cc;
$form-value-text-color-dark: #1c1d21;
$country-code-chip-bg-fill: #fafbfc;
$country-code-chip-border-color: #eeeeee;
$summary-card-border: #e4ebf6a2;
$summary-card-rounded-radius: 12px;
$summary-card-header-bg: #eff2f04d;

@import './skin';
@import './ftaCalc';
