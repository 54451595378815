.map-wrapper {
  height: 420px;
  border-radius: 14px;
  overflow: hidden;
}
.map-container {
  width: 100%;
  height: 100%;

  .info-window {
    max-width: 200px;

    .name {
      margin: 0 0 10px;
      padding: 0;
    }
    .content {
      font-size: 14px;
      line-height: 1.3;
    }
  }

  .gm-bundled-control {
    width: 28px;
    right: 0px !important;

    .gmnoprint {
      div {
        width: 28px !important;
        height: 56px !important;
        border-radius: 8px !important;

        button {
          width: 28px !important;
          height: 28px !important;
          border-radius: 8px;

          img {
            width: 12px !important;
            height: 16px !important;
          }
        }
        div {
          width: 20px !important;
          height: 1px !important;
          margin: 0px 4px;
        }
      }
    }
  }
}
