.input-component{
    position: relative;
  
    input{
        min-height: 56px;
        width: 100%;
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        padding:  16px 9px 0 20px;
        font-size: 1rem;
        outline: none;
        background-color: transparent;
         &:focus, &:not(:placeholder-shown){
          ~ label{
            top: 4px;
            font-size: 0.8rem;
      }
        &:focus {
        border: 1px solid var(--blue-link-color);
        }
        }
    }
    label{
        position: absolute;
        pointer-events: none;
        left: 20px;
        top: 16px;
        transition: 0.2s ease all;
        color: rgba(0,0,0,0.6);
    }
  .error{
    border-color: #f44336;

  }
  .text-error{
    color:  #f44336;
  }
  .before-icon, .after-icon{
    position: absolute;
  }
  .before-icon{
    top: 30%;
    right: 1%;
  }
  .after-icon{
    top: 25%;
    left: 0;
  }
}