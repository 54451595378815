.test-vessel {
  color: #f00;
}

@media (min-width: 767px) {
  .outer-container {
    padding: 30px;
    background-image: url('../img/backgroud/vs.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}

@media (min-width: 767px) {
  .vessel-search-container {
    width: 85% !important;
    top: 50%;
    margin: 0 auto;

    form {
      position: relative;
      z-index: 1;
    }
  }
}

.blue-label {
  color: #506cc9;
  font-weight: 800;
  padding: 0px !important;
}

.dp-showContentWithTransparent {
  font-size: x-small;
  opacity: 0.3;
  text-transform: uppercase;
}

.dp-showContent {
  font-size: small;
}

.search-button {
  min-height: 100%;
  min-width: 100% !important;
}

.padding-left-50 {
  padding-left: 50px !important;
}

.week-textField {
  flex-basis: 80px !important;
  padding-right: 0px !important;
}

.port-chip {
  background: none repeat scroll 0 0 #fafbfc;
  border: 2px solid #eeeeee;
  border-radius: 25%;
  align-items: center;
  font-weight: bold;
  font-size: x-small;
  position: absolute;
  right: 50px;
  top: 20px;
  padding: 3px !important;
}

.rs-content {
  display: flex;
  flex-direction: row;
  padding-top: 2px;
}

.rs-showContentWithTransparent {
  font-size: xx-small;
  opacity: 0.3;
}

.rs-showContent {
  font-size: x-small;
}

.rs-showContentWithTransparentLargerFont {
  font-size: small;
  opacity: 0.3;
}

.rs-showContentLargerFont {
  font-size: medium;
}

.vs-result-content-down {
  font-size: x-small;
  padding-top: 5px;
}

.rs-port {
  background: none repeat scroll 0 0 #fafbfc;
  border: 2px solid #eeeeee;
  border-radius: 50%;
  align-items: center;
  font-weight: bold !important;
  font-size: x-small !important;
  padding: 3px;
}

.rs-countryImageRight {
  height: 25px;
  padding-right: 5px;
  padding-top: 2px;
  width: 40px;
}

.rs-countryImageLeft {
  height: 25px;
  padding-left: 5px;
  padding-top: 2px;
  width: 40px;
}

.vs-noresult {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 50px !important;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.rs-grey {
  color: #8181a5;
  font-size: x-small;
  font-weight: bold;
}

.rs-green {
  color: rgb(148, 235, 187);
}

.rs-align-spacing {
  padding-top: 5px;
  padding-right: 20px;
}

.rs-align-spacing-grey {
  padding: 10px 20px;
  background: none repeat scroll 0 0 #f5f5fa;
  margin: 5px;
  border-radius: 10%;
  color: #8181a5;
}

.rs-showContent-bold {
  font-size: x-small;
  font-weight: bold;
}

.rs-bg-grey {
  background-color: #fafbfc;
}

.rs-filter-header {
  font-weight: bold;
  font-size: medium;
}

.rs-filter-subheader {
  font-weight: bolder !important;
  font-size: smaller !important;
  text-transform: uppercase;
  padding: 10px;
}

.clear-all-button {
  @extend .search-button;
  color: fuchsia;
}

.rs-filter-card {
  padding-bottom: 30px;
}

.rs-filter-card-header {
  padding-bottom: 20px;
}

.vs-table-cell-padding {
  padding: 13px !important;
}

.vs-carrier-image {
  object-fit: contain !important;
}

.truncate {
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.download {
  text-decoration: none !important;
}

.vs-nofile {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.truncate_portPair {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vs-break-word {
  overflow-wrap: break-word;
}
