/** 
 * @moduleName: web responsive
 * @createdDate: 08-aug-2020
 * @author: senthilkumar-r
 * @copyright: GeTS
 */

/* 
 * This stylesheet has web responsive style declarations i.e. Desktop, Mobile
 */

 @charset "utf-8";

 @import './skin';
 @import './helpers';
 
 // Small devices (landscape phones, 576px and up)
 @media (min-width: 576px) {
 }
 
 // Medium devices (tablets, 768px and up)
 @media (min-width: 768px) {
 }
 
 // Large devices (desktops, 992px and up)
 @media (min-width: 992px) {
 }
 
 // Extra large devices (large desktops, 1200px and up)
 @media (min-width: 1200px) {
 }
 
 // Small devices (landscape phones, 576px and up)
 @media screen and (max-width: 576px) {
   /* [Login Page] --- Starts Here */
   .ng-login-jumbotron-bg {
     background-size: 250% !important;
     background-position: top center;
   }
 
   .ng-login-form-container {
     height: 100%;
     display: flex !important;
     flex-direction: column;
     position: relative;
     overflow: hidden;
 
     .ng-login-form-header {
       margin-top: 10px;
 
       .ic-bg-white {
         background: rgba(0, 0, 0, 0.5);
         opacity: 0.5;
       }
     }
 
     .ng-login-card {
       padding-right: 0 !important;
       position: absolute !important;
       left: 0;
       bottom: 0px;
       margin-bottom: -20px;
       width: 100%;
 
       .ng-login-card-content {
         padding: 25px;
       }
     }
 
     .ng-login-card-footer {
       margin-top: 10px;
     }
   }
   /* [Login Page] --- Ends Here */
 
   // Dashboard Tile header actions alignment with desktop and mobile view
   .ng-dashboard-tiles {
     .ng-dashboard-tile-header {
       flex-direction: column;
 
       &__action,
       &__content {
         width: 100%;
       }
 
       .widget-actions-wrapper {
         display: flex;
         align-items: flex-end;
         flex-direction: column-reverse;
         margin-top: -20px;
 
         button {
           margin-bottom: 20px;
         }
       }
 
       .ng-toggle-btn-group {
         width: 100%;
         justify-content: center;
 
         .ng-toggle-btn:last-of-type {
           margin-right: 0 !important;
         }
       }
     }
   }
 } ;
 