/* 
 * @view: Login page 
 * @issue: Each application has its own logo; for calista aligned it differently
 */
.ng-login-card {
  .ng-login-card-header {
    flex-direction: column;

    .card-item:first-of-type {
      justify-content: flex-start;

      margin-bottom: 12px;
      margin-block-end: 12px;
    }
  }
}

.test-booking {
  color: #f00;
  font-size: 19px;
}

.frb-card-label {
  //font-family: 'Lato, Regular' !important;
  font-family: inherit !important;
  font-size: 12px !important;
  color: #8996af !important;
}

.frb-card-label-large-black {
  //font-family: 'Lato, Regular' !important;
  font-family: inherit !important;
  font-size: 14px !important;
  color: #1c1d21cc !important;
}

.frb-card-label-large-grey {
  //font-family: 'Lato, Regular' !important;
  font-family: inherit !important;
  font-size: 14px !important;
  color: #8996af !important;
}

.frb-card-content {
  //font-family: 'Lato-Bold' !important;
  font-family: inherit !important;
  font-size: 14px !important;
  width: 100%;
}

.frb-card-content-small {
  font-family: inherit !important;
  font-size: 10px !important;
  font-weight: bold !important;
}

.frb-card-content-bold {
  @extend .frb-card-content;
  font-weight: bold !important;
}

/**
 * Helpers
 * Page: Summary screens i.e. Manage Booking
 */
$form-label-text-color-light: #8996af;
$form-value-text-color-light: #1c1d21cc;
$form-value-text-color-dark: #1c1d21;
$country-code-chip-bg-fill: #fafbfc;
$country-code-chip-border-color: #eeeeee;

.form-label-colon {
  &::after {
    // content: ':';
    content: attr(data-label-suffix);
    font-size: inherit;
    color: inherit;
    display: inline-block;

    margin-right: 3px;
    margin-inline-end: 3px;
  }
}

.field-label {
  color: $form-label-text-color-light;
  font-size: 12px !important;
  font-weight: 400;
}

.field-value {
  &.light {
    color: $form-value-text-color-light;
  }

  &.dark {
    color: $form-value-text-color-dark;
  }

  font-size: 14px !important;
  font-weight: 400;
}

.field-country-flag {
  width: 20px;
  height: 20px;
  border-radius: 50%;

  margin-right: 4px;
  margin-inline-end: 4px;
}

.field-country-name {
  color: $form-value-text-color-dark;
  font-size: 16px !important;
  font-weight: 500;
}

.field-country-code {
  color: $form-value-text-color-dark;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: $country-code-chip-bg-fill !important;
  border: 1px $country-code-chip-border-color solid !important;
  min-height: 18px;
  box-sizing: border-box;

  padding-left: 6px;
  padding-right: 6px;
  padding-inline: 6px;

  padding-top: 3px;
  padding-bottom: 3px;
  padding-block: 3px;
}

.field-vertical--label-value {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  margin-bottom: 8px;
  margin-block-end: 8px;

  .field-vertical--item {
    width: 100%;
    display: inline-flex;
    flex: 1;

    &:first-of-type {
      // margin-bottom: 5px;
      // margin-block-end: 5px;
    }

    label {
      margin: 0;
    }
  }
}

.text-right-align {
  justify-content: flex-end !important;
}

// Font sizes
$max-font-size: 150;
@for $i from 1 through $max-font-size {
  .font-#{$i} {
    font-size: $i + px !important;
  }
}

/**
 * Component: tabs button, filter panel, summary cards etc.
 * Page: Summary screens i.e. Manage Booking
 */

$summary-card-border: #e4ebf6a2;
$summary-card-rounded-radius: 12px;
$summary-card-header-bg: #eff2f04d;

// Summary cards
.summary-list-items--container {
  border: none;
  box-shadow: none;

  .summary-list-item--card {
    border: 1px $summary-card-border solid;
    border-radius: $summary-card-rounded-radius;
    box-shadow: none;

    margin-bottom: 14px;
    margin-block-end: 14px;
  }
}

.summary-list-item--card {
  position: relative;
  transition: background-color 0.2s ease-in, box-shadow 0.2s ease-in;

  &:hover {
    background-color: rgba(240, 240, 240, 0.147);
    box-shadow: 0 1px 0 -1px rgba(63, 63, 68, 0.1),
      0 1px 2px 0 rgba(63, 63, 68, 0.2);
  }

  // Card header
  .cng-summary-card--header-box {
    background-color: $summary-card-header-bg;
    flex: 1;
    width: calc(100% - 4px);
    height: 44px;
    margin: 2px;
    border-radius: 10px;

    padding-top: 0;
    padding-bottom: 0;
    padding-block: 0;

    padding-left: 10px;
    padding-right: 4px;
    padding-inline: 10px 4px;

    position: relative;
    z-index: 1;

    .cng-summary-card--header-box--left,
    .cng-summary-card--header-box--right {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .cng-summary-card--header-box--right {
      .header-box--right-col-1,
      .header-box--right-col-2 {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .header-box--right-col-1 {
        flex: 1;
      }

      .header-box--right-col-2 {
        flex-wrap: nowrap;

        &.cta-box {
          width: 36px;
          height: 100%;
        }

        .cng-summary-card--more-actions-wrapper {
          // margin: 4px;

          button {
            width: 36px !important;
            height: 36px !important;

            svg {
              font-size: 18px;
              font-weight: 200;
            }
          }
        }
      }
    }

    .field-vertical--label-value {
      margin-bottom: 0px;
      margin-block-end: 0px;

      .field-vertical--item {
        &:first-of-type {
          margin-bottom: -3px;
          margin-block-end: -3px;
        }
      }
    }
  }

  // Card body
  .cng-summary-card--body-box {
    .MuiPaper-rounded {
      border-radius: 0;
    }

    .cng-summary-card--body-grid {
      padding-left: 0;
      padding-right: 0;
      padding-inline: 0;

      padding-top: 15px;
      padding-bottom: 15px;
      padding-block: 15px;
    }

    [class*='focusHighlight'] {
      display: none;
    }

    &:hover {
      // background-color: rgba(240, 240, 240, 0.147) !important;

      [class*='focusHighlight'] {
        display: block;
        background-color: rgba(240, 240, 240, 0.147) !important;
      }

      [class*='TouchRipple'] {
        display: none;
      }
    }
  }

  // Card footer
  .cng-summary-card--footer-box {
    flex: 1;
    width: calc(100% - 4px);
    height: 36px;
    margin: 2px;
    border-radius: 10px;

    padding-top: 0;
    padding-bottom: 0;
    padding-block: 0;

    padding-left: 10px;
    padding-right: 10px;
    padding-inline: 10px;

    position: relative;
    z-index: 1;

    .cng-summary-card--footer-box--left,
    .cng-summary-card--footer-box--right {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .cng-summary-card--footer-box--left {
      span {
        &:first-of-type {
          margin-right: 5px;
          margin-inline-end: 5px;
        }
        &:not(:first-of-type) {
          margin-right: 8px;
          margin-inline-end: 8px;
        }

        label {
          margin-bottom: 0;
          margin-block-end: 0;

          &:nth-child(2) {
            font-weight: 500;
          }
        }
      }
    }

    .cng-summary-card--footer-box--right {
      .footer-box--right-col-1,
      .footer-box--right-col-2 {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .footer-box--right-col-1 {
        flex: 1;
      }

      .footer-box--right-col-2 {
        flex-wrap: nowrap;

        &.cta-box {
          width: 36px;
          height: 100%;
        }

        .cng-summary-card--more-actions-wrapper {
          // margin: 4px;

          button {
            width: 36px !important;
            height: 36px !important;

            svg {
              font-size: 18px;
              font-weight: 200;
            }
          }
        }
      }
    }
  }
}

// Fields in cards
.cng-summary-card--country-value {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-block-end: 8px;
  overflow: hidden;

  .field-country-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    min-height: 20px;

    .field-country-name {
      margin-inline-end: 12px;
    }

    .field-country-code {
      margin-inline: 5px 10px;
      margin-block: 5px;
    }
  }
}

// Summary screen header
.cng-summary--header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  &-col {
    display: flex;
    align-items: center;

    &.auto {
      width: auto;
      flex: 1;
    }

    &.cta-46 {
      width: 46px;
      height: 46px;
    }
  }
}

// Avatar in card component
$card-avatar-width: 50px;
$card-avatar-height: 50px;
$card-avatar-fill: rgba(0, 0, 0, 0.031);
$card-avatar-svg-fill: rgb(227, 231, 236);

.cng-summary-card--avatar {
  width: $card-avatar-width !important;
  height: $card-avatar-height !important;

  
  &.circle {
    border-radius: 50% !important;
  }

  // This class is only applied when src is not set for the avatar.
  // |-> and svg icon is added to the display.
  &.default-bg {
    background-color: $card-avatar-fill !important;
    color: $card-avatar-svg-fill !important;
  }
  
  .img-src {
    object-fit: contain !important;
  }
}

// Tab button
.frb-tabs-btn-wrapper {
  display: flex;
  flex-wrap: wrap;

  button {
    color: $primary-gray;
    font-weight: 600;

    padding-left: 10px;
    padding-right: 10px;
    padding-inline: 10px;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-block: 10px;

    &:not(:first-of-type) {
      margin-left: 4px;
      margin-inline-start: 4px;
    }

    &.tab-active {
      color: $primary-dark;
      border-color: $outline-resting-gray;
    }

    em {
      color: $primary-gray;
      font-style: normal;
      font-weight: 400;
      margin-left: 3px;
      margin-inline-start: 3px;
    }
  }
}

/**
 * Component: showing records count label
 * Page: Summary screens i.e. Manage Booking
 */
.summary-count-label-wrapper {
  display: inline-flex;
  min-height: 40px;
  align-items: center;

  label {
    color: $primary-gray;
    font-size: 12px;
    margin: 0;
  }
}

/**
 * Component: filter panel
 * Page: Summary screens i.e. Manage Booking
 */
$filter-panel-box-bg: #8996af10;
$filter-panel-rounded-radius: 10px;

@mixin occupy-fifty-per-width($percent, $negativeValue: 0px) {
  // margin-right is assigned with 5px, refer li styles
  min-width: calc(#{$percent} - #{$negativeValue});

  // margin-right is assigned with 5px, refer li styles
  max-width: calc(#{$percent} - #{$negativeValue});
}

.cng-filter-pane--wrapper {
  padding-bottom: 0 !important;
  padding-block-end: 0 !important;

  .cng-filter-pane--section-footer + .cng-filter-pane--section-footer {
    margin-top: 3px;
    margin-block-start: 3px;

    &:last-of-type {
      .cng-filter-pane--footer {
        border-bottom-left-radius: $filter-panel-rounded-radius;
        border-bottom-right-radius: $filter-panel-rounded-radius;

        border-end-start-radius: $filter-panel-rounded-radius;
        border-end-end-radius: $filter-panel-rounded-radius;
      }
    }
  }
}

// Header
.cng-filter-pane--header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 36px;

  margin-bottom: 8px;
  margin-block-end: 8px;

  flex: 1;
  width: 100%;

  .cng-filter-pane--header-label-box {
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;

    .cng-filter-pane--header-label {
      color: $primary-dark;
      font-size: 18px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .cng-filter-pane--header-cta-box {
    align-items: center;
    display: flex;
    height: 100%;
    max-width: 100px;
  }
}

// Filter sections
.cng-filter-pane--section-container {
  display: flex;
  flex-direction: column;

  // To offset the left and right margin
  margin-left: -15px;
  margin-right: -15px;
  margin-inline: -15px;

  header,
  footer {
    height: 50px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    padding-left: 16px;
    padding-inline-start: 16px;

    background-color: $filter-panel-box-bg;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-bottom: 0;
      margin-block-end: 0;
    }
  }

  /* Section header :: left and right pane --- Starts here */
  .cng-filter-pane--section-header-label-box,
  .cng-filter-pane--section-header-cta-box {
    display: flex;

    height: 100%;
    align-items: center;
  }

  .cng-filter-pane--section-header-label-box {
    flex: 1;
  }
  .cng-filter-pane--section-header-cta-box {
    max-width: 100px;
  }
  /* Section header :: left and right pane --- Ends here  */

  .cng-filter-pane--section-header-label {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $primary-dark;
  }

  // Section header :: custom cta buttons
  .cng-filter-pane--header-chip {
    margin-right: 16px;
    margin-inline-end: 16px;

    &--label {
      padding: 0;
    }

    &--cta {
      margin-left: 7px;
      margin-right: 3px;
      margin-inline: 7px 3px;
    }

    &--icon {
      margin-left: 12px;
      width: 16px;
      height: 16px;
    }
  }

  article {
    display: flex;

    padding-left: 10px;
    padding-right: 10px;
    padding-inline: 10px;

    padding-top: 24px;
    padding-bottom: 24px;
    padding-block: 24px;
  }

  [role='radiogroup'] {
    margin-left: -20px; //10px
    margin-inline-start: -20px;
  }

  .cng-filter-criteria--chkbox-container {
    // margin-left: 6px;
    // margin-inline-start: 6px;

    width: 100%;
    display: flex;
    flex-flow: column wrap;

    ul {
      margin: 0;
      padding: 0;

      [class*='MuiCheckbox'] {
        padding: 8px;
      }

      li {
        list-style: none;

        margin-right: 5px;
        margin-inline-end: 5px;

        width: 100%;

        [class*='MuiCheckbox'] {
          padding: 8px;
        }
      }

      &.filter-criteria--all {
        display: flex;

        margin-bottom: 10px;
        margin-block-end: 10px;

        /** 
         * Few checkbox groups from CNG framework is not wrapper with <li> elements under <ul> 
         * Fix: Used the similar separation i.e. 50% width for two-column layout.
        */
        & > label[class*='MuiFormControlLabel-root']:first-child {
          width: 100%;
        }
        /* Ends here */

        li {
          display: inline-flex;
          width: 100%;
          white-space: normal;
          word-break: break-all;
        }
      }

      &.filter-criteria--others {
        display: flex;
        flex-flow: row wrap;

        /** 
         * Few checkbox groups from CNG framework is not wrapper with <li> elements under <ul> 
         * Fix: Used the similar separation i.e. 50% width for two-column layout.
        */
        & > label[class*='MuiFormControlLabel-root'] {
          @include occupy-fifty-per-width(50%);

          & + label[class*='MuiFormControlLabel-root'] {
            @include occupy-fifty-per-width(50%);
          }
        }
        /* Ends here */

        li {
          display: inline-flex;

          @include occupy-fifty-per-width(50%, 5px);
        }

        &.occupy-full-width {
          li {
            @include occupy-fifty-per-width(100%, 5px);
          }
        }
      }
    }
  }
}

// Footer
.cng-filter-pane--footer {
  .cng-filter-pane--footer-label-box {
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;

    .cng-filter-pane--footer-label {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      color: $primary-dark;
    }
  }

  .cng-filter-pane--footer-cta-box {
    align-items: center;
    display: flex;
    height: 100%;
    max-width: 100px;

    .ng-form-control-toggle-switchbase {
      width: auto;
      height: auto;
    }
  }
}

// Filter pane controls wrapper i.e. checkbox, radio button groups
.cng-filter-pane--controls-wrapper {
  width: 100%;

  & > fieldset {
    width: 100%;
  }

  label[class*='MuiFormControlLabel'] {
    margin-bottom: 0;
    margin-block-end: 0;

    margin-left: 0;
    margin-right: 0;
    margin-inline: 0;
  }

  [role='radiogroup'] {
    [class*='MuiRadio'] {
      padding: 8px;
    }
  }
}
