/** 
 * @moduleName: form components
 * @createdDate: 08-aug-2020
 * @author: senthilkumar-r
 * @copyright: GeTS
 */

/* 
 * This stylesheet has the style declarations for form elements like text field, buttons etc
 */

@charset "utf-8";

@import './skin';
@import './_helpers';

/* Form Group --- Starts Here */
.ng-form-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;

  .group-field:nth-child(1) {
    width: 100%;
  }

  &.beside {
    .group-field:nth-child(1) {
      position: relative;
      z-index: 10;
    }

    .group-field:nth-child(2) {
      width: 100%;
      margin-left: -12px;

      .ng-autocomplete {
        input[type='text'],
        input[type='search'] {
          padding-left: 50px !important;
          background-position: 20px center !important;
        }
      }
    }
  }
}
/* Form Group --- Ends Here */

/* [Text field -> Variant Filled] --- Starts Here */
.ng-form-control {
  &.auto-complete,
  &.ng-form-control-filled {
    border-radius: $textbox-radius + px;
    border-color: $textbox-border-color;
    border-width: 1px;
    border-style: solid;
    background-color: $textbox-normal-bg !important;

    &::before,
    &::after {
      border-bottom: none !important;
    }

    &:hover {
      background-color: $textbox-focus-bg !important;
      border-color: $textbox-border-color !important;
    }
  }

  &.ng-form-control-filled-focused {
    background-color: $textbox-focus-bg !important;
    border-color: $textbox-active-border-color !important;
    box-shadow: 0 0 1px 1px rgba(94, 129, 244, 1);

    fieldset {
      border-width: 2px;
      border-color: $textbox-active-border-color !important;
    }
  }

  &.ng-form-control-filled-error {
    background-color: $error-bg-red !important;
    border-color: $error-border-red !important;
    box-shadow: 0 0 1px 1px $error-border-red;

    fieldset {
      border-color: $error-border-red !important;
    }
  }

  &:focus {
    background-color: $textbox-focus-bg !important;
    border-color: red !important;
  }

  // Below style is to override padding top for the text field and dropdown field which has no label
  &.no-label {
    input[type='text'],
    input[type='number'],
    .select-textfield {
      padding-top: 10px !important;
    }
  }

  &.edit-field {
    font-size: 14px;
  }

  // Label followed by input control
  &-label-field {
    display: flex;
    flex-direction: row;
    align-items: center;

    .label {
      color: $primary-gray;
      font-size: 14px;
      font-weight: 400;
      margin-right: 8px;
    }
  }
}

// Auto Complete text fields
.ng-autocomplete {
  padding: 0 !important;
  border-radius: 8px !important;

  &:hover,
  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    border: 0 !important;
  }

  fieldset,
  fieldset:hover {
    padding: 0 !important;
    border-width: 1px !important;
    // border-color: $textbox-border-color !important;
  }

  &.ng-form-control-filled-focused {
    input[type='search'],
    input[type='text'] {
      background-image: url('../img/app-icons/ic_search_blue.svg');
    }

    fieldset {
      // background-color: $textbox-focus-bg !important;
      // border-color: $textbox-active-border-color !important;
      box-shadow: 0 0 1px 1px rgba(94, 129, 244, 1);
    }
  }

  input[type='search'],
  input[type='text'] {
    border-radius: 8px;
    // border-color: $textbox-border-color;
    border-width: 0px;
    border-style: solid;
    // background-color: $textbox-normal-bg !important;
    background-image: url('../img/app-icons/ic_search_gray.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
    padding-left: 40px !important;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
    outline: none !important;
    transition: border 0.3s ease-in, box-shadow 0.3s ease-in;

    &:hover {
      // background-color: $textbox-focus-bg !important;
      // border-color: $textbox-border-color !important;
    }

    &:focus {
      // background-color: $textbox-focus-bg !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
}

// Label followed by input control
.label-field {
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    color: $primary-gray;
    font-size: 14px;
    font-weight: 400;
    margin: 0 8px;
  }

  button:disabled {
    opacity: 0.5;
  }
}

// Placeholder Label
.ng-form-control-label {
  // color: fade(currentColor, 10%) !important;

  &.ng-form-control-label-filled {
    color: $label-secondary-color;

    &:hover {
      color: $label-hover-color;
    }
  }

  &.ng-form-control-label-filled-focused {
    // color: $primary-blue !important;
  }

  &.ng-form-control-label-error {
    // color: $error-border-red !important;
  }
}

// Helper Text
.ng-form-control-helper-label,
.ng-form-control-helper-label-filled {
  font-size: 12px;
  color: $label-secondary-color;

  &.ng-form-control-helper-label-error {
    color: $error-border-red;
  }
}

// Input text control
.ng-input-control-wrapper {
  width: 100%;

  .ng-input-text {
    border-radius: $textbox-radius + px;
    // border-color: $textbox-border-color;
    border-width: 1px;
    border-style: solid;
    // background-color: $textbox-normal-bg !important;
    padding: 10px 12px 10px;
    width: 100%;
    outline: none !important;

    &:hover {
      // background-color: $textbox-focus-bg !important;
    }

    &:focus {
      // background-color: $textbox-focus-bg !important;
      // border-color: $textbox-active-border-color !important;
      box-shadow: 0 0 1px 1px rgba(94, 129, 244, 1);
    }

    &.edit-field {
      font-size: 14px;
    }
  }
}
/* [Text field -> Variant Filled] --- Ends Here */

/* [Select field -> Variant Filled] --- Starts Here */
// This dropdown is used in login page
.ng-form-control-select {
  border-radius: 6px !important;
  background-color: $white !important;
  border: 1px $select-border-color solid !important;
  transition: background-color 0.3s ease-in-out !important;

  &::before,
  &::after {
    border-bottom: 0px !important;
  }

  &:hover {
    background-color: $select-focus-bg !important;

    &::before,
    &::after {
      border-bottom: 0px !important;
    }
  }

  &:active,
  &:focus {
    background-color: $select-focus-bg;
  }

  &.small {
    min-width: 80px !important;

    .dropdown-arrow {
      right: 0;
    }
  }

  &.xtra-small {
    min-width: 40px !important;

    .dropdown-arrow {
      right: 0;
    }
  }

  .ng-form-control-select-placeholder {
    font-size: 12px;
    font-weight: 700;
    color: $label-secondary-color;
    padding: 5px 32px 5px 8px;
  }
}

.ng-form-control-dropdown {
  width: 100%;

  .label {
  }
  .ng-select {
    border-radius: $textbox-radius + px;
    border-color: $textbox-border-color;
    border-width: 1px;
    border-style: solid;
    background-color: $textbox-normal-bg !important;

    &::before,
    &::after {
      border-bottom: none !important;
    }

    &:hover {
      background-color: $textbox-focus-bg !important;
    }

    .placeholder {
      font-size: 12px;
      font-weight: 700;
      color: $label-secondary-color;
      padding: 10px 32px 10px 8px;
    }

    .dropdown-arrow {
      right: 0;
    }
  }
}

.ng-form-control-textselect {
  width: 100%;
}

.select-menu-props {
  width: auto;
}

.menu-list {
  width: 100%;
  // background-color: $select-list-fill !important;

  li {
    &:hover {
      // background-color: $select-list-item-hover-fill !important;
    }

    &[aria-selected='true'] {
      // background-color: $select-list-item-selected-fill !important;
    }
  }
}

// Filter dropdown preceding the search textbox
.autocomplete-filter {
  border-radius: 8px;

  .select-textfield {
    padding-top: 12px !important;
    border-radius: 8px;
  }

  .ng-form-control-label {
    top: -7px;
    color: $primary-dark;
  }

  .ng-form-control-filled {
    border-radius: 8px;
    // background-color: #efefef !important;

    &-focused,
    &:hover {
      // background-color: #e6ebff !important;
    }
  }
}
/* [Select field -> Variant Filled] --- Ends Here */

/* [Checkbox] --- Starts Here */

.ng-form-control-checkbox {
  svg {
    width: 20px;
    height: 20px;
  }

  &.checked {
    color: $checkbox-selected-color !important;
  }

  &.disabled {
    opacity: 0.6 !important;
  }
}

.ng-form-control-checkbox-checked {
  color: $checkbox-selected-color !important;

  &:disabled {
    // color: rgba(94, 129, 244, 0.8) !important;
    color: rgba(244, 94, 169, 0.8) !important;
  }
}

.ng-form-control-checkbox-label {
  font-size: 14px;
  font-weight: 400;
  // color: $checkbox-label-color;
}

/* [Checkbox] --- Ends Here */

/* [Radio Button] --- Starts Here */
.ng-form-control-radio {
  svg:nth-child(1) {
    fill: #eeeeee !important;
  }

  &.checked {
    svg {
      fill: $primary-blue !important;
    }
  }
}
/* [Radio Button] --- Ends Here */

/* [Toggle Button] --- Starts Here */
.ng-form-control-toggle {
  // width: 46px !important;
  // height: 26px !important;
  // padding: 0 !important;

  &-thumb {
    // width: 24px !important;
    // height: 24px !important;
    // border: 1px #1c1d2133 solid !important;
    // background-color: $white !important;
  }

  &-track {
    // border-radius: calc(26px / 2) !important;
    // border-width: 0 !important;
    // background-color: $toggle-track-off-bg !important;
    opacity: 1 !important;
    transition: background-color 0.3s ease-in, border 0.3s ease-in !important;
  }

  &-switchbase {
    top: 1px !important;
    left: 1px !important;
    padding: 0 !important;

    &.disabled + .ng-form-control-toggle-track {
      // background-color: #f0f0f365 !important;
    }

    &.disabled {
      .ng-form-control-toggle-thumb {
        // border-color: #1c1d2115 !important;
      }
    }

    &.checked + .ng-form-control-toggle-track {
      // background-color: rgba(94, 129, 244, 1) !important;
    }

    &.checked.disabled + .ng-form-control-toggle-track {
      // background-color: rgba(94, 129, 244, 0.6) !important;
    }

    &.checked.disabled {
      .ng-form-control-toggle-thumb {
        // background-color: $white !important;
        // border-color: #1c1d2133 !important;
      }
    }
  }
}
/* [Toggle Button] --- Ends Here */

/* [Buttons] --- Starts Here */
// Primary Buttons
.ng-button-filled-primary {
  background-color: $btn-primary-state-normal !important;
  box-shadow: 0 0 1px 0 rgba(94, 129, 244, 0.32),
    0 2px 2px -2px rgba(94, 129, 244, 0.25) !important;
  border-radius: 8px !important;
  color: $btn-primary-text !important;
  text-transform: none !important;

  &:hover {
    background-color: $btn-primary-state-hover !important;
    border-color: $btn-primary-state-hover !important;
  }

  &:focus {
    background-color: $btn-primary-state-focus !important;
    border-color: $btn-primary-state-focus !important;
  }

  &:active {
    background-color: $btn-primary-state-active !important;
    border-color: $btn-primary-state-active !important;
  }

  &:disabled {
    background-color: $light-gray-bg !important;
    border-color: $light-gray-bg !important;
    box-shadow: none !important;
    color: $gray-overlay !important;
  }

  &.link-button {
    padding: 6px 13px !important;

    &:hover {
      text-decoration: none !important;
    }
  }
}

// Secondary Buttons
.ng-button-filled-secondary {
  background-color: $btn-secondary-state-normal !important;
  border-radius: 8px !important;
  border-color: $btn-secondary-state-normal !important;
  box-shadow: 0 0 1px 0 rgba(94, 129, 244, 0.1),
    0 2px 2px -2px rgba(94, 129, 244, 0.25) !important;
  color: $btn-secondary-text !important;
  text-transform: none !important;

  &:hover {
    background-color: $btn-secondary-state-hover !important;
    border-color: $btn-secondary-state-hover !important;
  }

  &:focus {
    background-color: $btn-secondary-state-focus !important;
    border-color: $btn-secondary-state-focus !important;
  }

  &:active {
    background-color: $btn-secondary-state-active !important;
    border-color: $btn-secondary-state-active !important;
  }

  &:disabled {
    background-color: $light-gray-bg !important;
    border-color: $light-gray-bg !important;
    box-shadow: none !important;
    color: $gray-overlay !important;
  }

  &.link-button {
    padding: 6px 13px !important;

    &:hover {
      text-decoration: none !important;
    }
  }
}

// Outline Buttons
.ng-button-outline {
  // background-color: $btn-outline-state-normal-fill !important;
  border-radius: 8px !important;
  // border-color: $btn-outline-state-normal-border !important;
  box-shadow: 0 0 1px 0 rgba(94, 129, 244, 0.1),
    0 2px 2px -2px rgba(94, 129, 244, 0.25) !important;
  // color: $btn-outline-text;
  text-transform: none !important;
  min-width: 140px;

  // &:hover {
  //   background-color: $btn-outline-state-hover-fill !important;
  //   border-color: $btn-outline-state-hover-border !important;
  // }

  // &:focus {
  //   background-color: $btn-outline-state-focus-fill !important;
  //   border-color: $btn-outline-state-focus-border !important;
  // }

  // &:active {
  //   background-color: $btn-outline-state-active-fill !important;
  //   border-color: $btn-outline-state-active-border !important;
  // }

  // &:disabled {
  //   background-color: $btn-outline-state-disabled-fill !important;
  //   border-color: $btn-outline-state-disabled-border !important;
  //   border-width: 1px;
  //   border-style: solid;
  //   box-shadow: none !important;
  //   color: $gray-overlay !important;
  // }

  &.link-button {
    padding: 6px 13px !important;

    &:hover {
      text-decoration: none !important;
    }
  }
}

// Text Buttons
.ng-button-text {
  background-color: none !important;
  box-shadow: none !important;
  border: 0px !important;
  border-radius: 8px !important;
  color: $btn-secondary-text !important;
  text-transform: none !important;

  &:hover {
    background-color: $btn-secondary-state-hover !important;
    border-color: $btn-secondary-state-hover !important;
  }

  &:focus {
    background-color: $btn-secondary-state-focus !important;
    border-color: $btn-secondary-state-focus !important;
  }

  &:active {
    background-color: $btn-secondary-state-active !important;
    border-color: $btn-secondary-state-active !important;
  }

  &:disabled {
    background-color: $light-gray-bg !important;
    border-color: $light-gray-bg !important;
    box-shadow: none !important;
    color: $gray-overlay !important;
  }

  &.link-button {
    padding: 6px 13px !important;

    &:hover {
      text-decoration: none !important;
    }
  }
}

// Function Buttons
// Filled
.ng-button-func-primary {
  background-color: $btn-primary-state-normal !important;
  box-shadow: 0 0 1px 0 rgba(94, 129, 244, 0.32),
    0 2px 2px -2px rgba(94, 129, 244, 0.25) !important;
  border-radius: 10px !important;
  color: $btn-primary-text !important;
  text-transform: none !important;

  &:hover {
    background-color: $btn-primary-state-hover !important;
    border-color: $btn-primary-state-hover !important;
  }

  &:focus {
    background-color: $btn-primary-state-focus !important;
    border-color: $btn-primary-state-focus !important;
  }

  &:active {
    background-color: $btn-primary-state-active !important;
    border-color: $btn-primary-state-active !important;
  }

  &:disabled {
    background-color: $light-gray-bg !important;
    border-color: $light-gray-bg !important;
    box-shadow: none !important;
    color: $gray-overlay !important;
  }

  &.gray {
    background-color: $btn-gray-normal-fill !important;

    &:active {
      background-color: $btn-gray-active-fill !important;
    }

    svg {
      color: $primary-gray;
    }

    &:hover {
      background-color: $btn-gray-hover-fill !important;
    }
  }

  $sizes: 36, 40, 44;

  @each $size in $sizes {
    &.size-#{$size}px {
      width: $size + px;
      height: $size + px;
    }
  }
}

// Outline
.ng-button-func-outline {
  background-color: $btn-outline-state-normal-fill !important;
  border-radius: 8px !important;
  border-color: $btn-outline-state-normal-border !important;
  border-width: 1px !important;
  border-style: solid !important;
  box-shadow: 0 0 1px 0 rgba(94, 129, 244, 0.1),
    0 2px 2px -2px rgba(94, 129, 244, 0.25) !important;
  color: $primary-blueish-gray !important;
  text-transform: none !important;

  &:hover {
    background-color: $btn-outline-state-hover-fill !important;
    border-color: $btn-outline-state-hover-border !important;
  }

  &:focus {
    background-color: $btn-outline-state-focus-fill !important;
    border-color: $btn-outline-state-focus-border !important;
  }

  &:active {
    background-color: $btn-outline-state-active-fill !important;
    border-color: $btn-outline-state-active-border !important;
  }

  &:disabled {
    background-color: $btn-outline-state-disabled-fill !important;
    border-color: $btn-outline-state-disabled-border !important;
    border-width: 1px;
    border-style: solid;
    box-shadow: none !important;
    color: $gray-overlay !important;
  }
}

// Advanced Components - Function buttons
// Refer: form.js for themeLibrary usage
.ng-button-func {
  &.primary,
  &.secondary,
  &.outline {
    border-radius: 10px !important;
    text-transform: none !important;
  }

  &.outline {
    border-width: 1px !important;
    border-style: solid !important;
  }
}

/* [Buttons] --- Ends Here */

/* [Tabs] --- Starts Here */
.ng-tabs {
  &__item {
    background-color: $tab-state-normal-fill !important;
    border-radius: 8px !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: transparent !important;
    color: $primary-blueish-gray !important;
    font-size: 14px !important;
    @extend .font-medium;
    padding: 5px 20px !important;
    margin: 5px !important;
    text-transform: unset !important;
    transition: color 0.3 ease-in, border-color 0.3 ease-in,
      background-color 0.3 ease-in, text-shadow 0.3 ease-in;

    &:focus,
    &-selected {
      // @extend .font-mediumBold;
      text-shadow: 0 0 0.65px $primary-dark, 0 0 0.65px $primary-dark;
      background-color: $tab-state-normal-fill !important;
      color: $primary-dark !important;
      border-color: $tab-state-focus-border !important;
    }

    &:hover {
      background-color: $tab-state-hover-fill !important;
    }
  }

  .ng-scrollbuttons {
    border-radius: 8px !important;
    margin: 3px;
    background-color: $tab-scroll-btn-normal-fill !important;

    &:hover {
      background-color: $tab-scroll-btn-hover-fill !important;
    }
  }
}

/* [Tabs] --- Ends Here */

/* [Alerts / Banners] --- Starts Here */
.ng-alert {
  background-color: $white;
  border-radius: 12px !important;
  box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.102) !important;

  &.ng-alert-info,
  &.ng-alert-success,
  &.ng-alert-error,
  &.ng-alert-warning {
    background-color: $white;
  }

  .ng-alert-action-icon {
    svg {
      color: $black;
      font-size: 20px;
    }
  }
}
/* [Alerts / Banners] --- Ends Here */

/* [Snackbars] --- Starts Here */
.ng-snackbar {
  border-radius: 12px !important;

  &.success {
    background-color: $snackbar-success !important;
  }

  &.error {
    background-color: $snackbar-error !important;
  }

  &.warning {
    background-color: $snackbar-warning !important;
  }

  &.info {
    background-color: $snackbar-info !important;
  }

  .ng-snackbar-action-icon {
    svg {
      color: $white;
      font-size: 16px;
    }
  }
}

//Noti Stack Component
.ng-snackbar-success,
.ng-snackbar-error,
.ng-snackbar-warning,
.ng-snackbar-info {
  border-radius: 12px !important;
}

.ng-snackbar-success {
  background-color: $snackbar-success !important;
}

.ng-snackbar-error {
  background-color: $snackbar-error !important;
}

.ng-snackbar-warning {
  background-color: $snackbar-warning !important;
}

.ng-snackbar-info {
  background-color: $snackbar-info !important;
}

/* [Snackbars] --- Ends Here */

/* [Chips / Tags] --- Starts Here */
.ng-chips {
  // background-color: $primary-azure !important;
  color: $primary-blue;
  height: 32px;

  &.small {
    height: 24px !important;
  }

  .label {
    // color: currentColor !important;
    font-size: 14px !important;
  }

  .avatar {
    // background-color: $white !important;
    border-width: 1px;
    border-style: solid;
  }

  .action-icon {
    // background-color: transparent !important;
    // color: $primary-blue !important;
    border-radius: 50%;
    transition: background-color 0.2s ease-in;
    width: 16px !important;
    height: 16px !important;

    &:hover {
      // background-color: $white !important;
    }
  }

  &.clickable {
    // background-color: $chip-filled-fill !important;

    &:hover {
      // background-color: $chip-filled-hover-fill !important;
    }
  }

  &.disabled {
    // background-color: $chip-filled-disabled-fill !important;
    // color: $chip-disabled-text !important;
    opacity: 1 !important;

    .avatar {
      // border-color: #f5f5fa !important;
      // color: $chip-disabled-text !important;
      opacity: 0.5;
    }

    .action-icon {
      // color: $chip-outline-disabled !important;
    }
  }

  &.outlined {
    // border-color: $chip-outline-border !important;
    // background-color: $white !important;
    // color: $primary-blue !important;

    &.clickable {
      &:hover {
        // background-color: $chip-filled-hover-fill !important;
      }
    }

    .avatar {
      // border: 1px #f5f5fa solid !important;
      opacity: 0.5;
    }

    .action-icon {
      &:hover {
        // background-color: $primary-azure !important;
        // color: $primary-blue;
      }
    }

    &.disabled {
      // border-color: $chip-outline-disabled !important;
      // color: $chip-disabled-text !important;
      opacity: 1 !important;

      .avatar {
        // border-color: #f5f5fa !important;
        // color: $chip-disabled-text !important;
      }

      .action-icon {
        // color: $chip-outline-disabled !important;
      }
    }
  }
}
/* [Chips / Tags] --- Ends Here */

/* [Circular Progress Indicator] --- Starts Here */
.ng-circular-prog {
  color: $primary-blue !important;
  svg {
    circle {
      stroke-linecap: round !important;
    }
  }
}
/* [Circular Progress Indicator] --- Ends Here */

/* [Linear Progress Indicator] --- Starts Here */
.ng-linear-prog {
  // background-color: $linear-progress-track !important;
  height: 6px !important;
  border-radius: 3px !important;

  .bar {
    // background-color: $secondary-green;
    border-radius: 3px !important;
  }
}
/* [Linear Progress Indicator] --- Ends Here */

/* [Date Picker Component] --- Starts Here */
.ng-static-datepicker {
  width: 100%;

  .current {
    background-color: $primary-blue;
  }

  .day {
    background-color: $primary-azure;
  }
}

.calendar-nav-button {
  border-radius: 6px !important;
  margin: 3px !important;

  &.static-picker {
    // background-color: $datepicker-navbtn-normal-fill !important;

    &:hover {
      // background-color: $datepicker-navbtn-hover-fill !important;
    }
  }

  &:not(.static-picker) {
    &:hover {
      // background-color: $datepicker-navbtn-normal-fill !important;
    }
  }
}

.ng-calendar-control-wrapper {
  border-radius: 12px !important;

  .MuiToolbar-root {
    border-radius: 12px !important;
  }

  .MuiPickersCalendarHeader-switchHeader {
    p {
      font-weight: 700 !important;
    }
  }

  .MuiPickersCalendarHeader-daysHeader {
    span {
      font-weight: 400 !important;
      // color: rgba(0, 0, 0, 1) !important;
    }
  }
}

// Override Datepicker Toolbar Text and Days Styling
.MuiPickersCalendarHeader-switchHeader {
  p {
    font-weight: 700 !important;
  }
}

.MuiPickersCalendarHeader-daysHeader {
  span {
    font-weight: 400 !important;
    // color: rgba(0, 0, 0, 1) !important;
  }
}
/* [Date Picker Component] --- Ends Here */

/* [Dialog Window] --- Starts Here */
.ng-dialog-wrapper {
  border-radius: 12px !important;

  .dialog-header {
    flex-direction: row;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 18px !important;
      font-weight: 700 !important;
      color: inherit;
    }

    &.has-border {
      // border-bottom: 1px #f6f6f6 solid !important;
    }

    &.has-action-icon {
      position: relative;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        position: relative;
      }

      button {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .dialog-body {
    p {
      font-size: 15px !important;
      font-weight: 400 !important;
      color: inherit;
    }
  }

  .dialog-footer {
    &.has-border {
      border-top: 1px #f6f6f6 solid !important;
    }
  }
}
/* [Dialog Window] --- Ends Here */

/* [Sliders: Horizontal] --- Starts Here */
@mixin sliderHorzConfig($height, $radius) {
  height: #{$height};
  border-radius: #{$radius};
}

@mixin sliderThumbConfig($size, $fill, $borderWidth, $borderColor) {
  width: $size;
  height: $size;
  // background-color: $fill;
  // border-color: $borderColor;
  border-style: solid;
  border-width: $borderWidth;
  box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.25);
}

@mixin sliderTooltipConfig($size, $fill, $textColor, $boxRadius) {
  min-width: $size;
  width: auto;
  height: $size;
  // background-color: $fill;
  border-radius: $boxRadius;
  // color: $textColor;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

@mixin sliderMarkConfig($size, $fill, $boxRadius: 50%) {
  width: $size;
  height: $size;
  // background-color: $fill !important;
  border-radius: $boxRadius;
}

.ng-slider {
  width: 100%;
  height: 6px;

  .rail {
    // background-color: $slider-rail-fill;
    margin-top: 1px;

    @include sliderHorzConfig(6px, 3px);
  }

  .track {
    // background-color: $slider-track-fill;
    margin-top: 1px;

    @include sliderHorzConfig(6px, 3px);
  }

  .thumb {
    @include sliderThumbConfig(16px, $slider-thumb-fill, 3px, $white);
  }

  .mark {
    margin-top: 1px;

    @include sliderMarkConfig(6px, $slider-mark-fill);

    &-active {
      // background-color: $slider-mark-active-fill !important;
    }
  }

  &.disabled {
    .rail {
      // background-color: $slider-rail-disabled-fill;

      @include sliderHorzConfig(6px, 3px);
    }

    .track {
      // background-color: $slider-track-disabled-fill;
      @include sliderHorzConfig(6px, 3px);
    }

    .thumb {
      @include sliderThumbConfig(
        16px,
        $slider-thumb-disabled-fill,
        3px,
        $white
      );

      box-shadow: 0px 0px 2px -1px rgba(0, 0, 0, 0.25) !important;
    }

    .mark {
      @include sliderMarkConfig(6px, $slider-mark-disabled-fill);
    }
  }
}

.ng-popper {
  .ng-tooltip-wrapper {
    @include sliderTooltipConfig(
      36px,
      $slider-tooltip-fill,
      $slider-tooltip-text,
      8px
    );
  }
}
/* [Sliders: Horizontal] --- Starts Here */

/* [Dropzone or File Upload] --- Starts Here */
.ng-dropzone-control-wrapper {
  .ng-dropzone-control {
    border-radius: 12px !important;
    // border-color: $dropzone-control-border;
    transition: border-color 0.3s ease-in, background-color 0.3s ease-in,
      opacity 0.3s ease-in;

    &:hover {
      border-color: $dropzone-hover-border !important;

      img,
      .dropzone-primary-text,
      .dropzone-secondary-text {
        opacity: 0.5;
      }
    }

    &.drag-active {
      border-color: $dropzone-hover-border !important;

      img,
      .dropzone-primary-text,
      .dropzone-secondary-text {
        opacity: 0.5;
      }
    }
  }

  .dropzone-primary-text {
    font-size: 18px !important;
    font-weight: 700;
  }

  .dropzone-secondary-text {
    font-size: 14px;
    font-weight: 400;
  }

  img,
  .dropzone-primary-text,
  .dropzone-secondary-text {
    transition: opacity 0.3s ease-in;
  }

  .helper-text {
    font-size: 12px;
    color: $dropzone-helpertext;
    margin-left: 10px;
  }

  .ng-dropzone-files-list {
    .ng-dropzone-files__list-item {
      position: relative;
      padding-bottom: 10px;
      padding-left: 0;
      border-bottom: 0;

      &::after {
        content: '';
        width: calc(100% - 52px);
        height: 1px;
        background-color: $dropzone-list-separator;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .file-format {
        background-color: $dropzone-file-icon-fill;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        width: 42px;
        height: 42px;
        border-radius: 50%;

        svg {
        }
      }

      .file-details {
        .file-name {
          font-size: 16px;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .file-size {
          font-size: 12px;
          font-weight: 400;
          color: $dropzone-file-size;
          margin-top: -5px;
        }
      }

      .row-more-actions {
        width: 42px;
        height: 42px;
        align-self: center;
        
        svg {
          color: $dropzone-helpertext;
          font-size: 18px;
        }
      }
    }
  }
}
/* [Dropzone or File Upload] --- Ends Here */

/* [Tiles] --- Starts Here */
// This is tile or card used in dasboard
.ng-dashboard-tiles {
  border-radius: 12px !important;
  border-color: $tile-border !important;
  box-shadow: 0px 2px 11px 0px $tile-shadow !important;
  padding: 11px 15px !important;

  &.small {
    min-height: 112px !important;
    max-height: 120px !important;
    // height: clamp(112px, 120px, 120px) !important;
  }

  &.medium {
    min-height: 244px !important;
    max-height: 252px !important;
    // height: clamp(244px, 244px, 250px) !important;
  }

  &.large {
    min-height: 350px !important;
    max-height: 358px !important;
    // height: clamp(350px, 350px, 358px) !important;
  }

  &.xlarge {
    min-height: 508px !important;
    max-height: 516px !important;
    // height: clamp(508px, 508px, 516px) !important;
  }

  &.horizontal {
    height: 244px !important;
  }
  // align-items: flex-start !important;

  .ng-tile-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .ng-tile-header {
    font-size: 16px !important;
    font-weight: 700;
    line-height: normal !important;
    margin-bottom: 0 !important;
    text-transform: unset !important;
    letter-spacing: unset !important;
  }

  .ng-tile-description {
    font-size: 10px !important;
    font-weight: 400;
    line-height: normal !important;
    margin-bottom: 0 !important;
    text-transform: unset !important;
  }

  .ng-tile-details {
    width: 100%;
    height: 100%;

    .ng-tile-value {
      font-size: 20px !important;
      font-weight: 700;
      margin: 5px 0 !important;
    }

    .plus-or-minus {
      font-size: 12px !important;
      font-weight: 400 !important;
    }

    .label-capsule {
      padding: 3px 10px;
      border-radius: 11px;
    }
  }

  .container {
    width: 100%;
    height: 100%;
  }

  .ng-smalltile-chart {
    canvas {
      height: 100px !important;
    }

    &.align-pie-top-30 {
      margin-top: -30px;
    }
  }

  $sizes: 10, 15, 20, 25, 30, 35, 40, 45, 50;
  @each $size in $sizes {
    .align-canvas-mtop-#{$size} {
      margin-top: -#{$size}px;
    }
  }

  .ng-mediumtile-chart {
    height: 200px;

    canvas {
      // height: 170px !important;
      // height: 95% !important;
    }

    &.line-chart {
      canvas {
        margin-top: -20px;
        height: calc(100% - 0px) !important;
      }
    }
  }

  // For Bigger Tiles / widgets
  .widget-title {
    // color: $primary-dark;
    font-size: 18px !important;
    font-weight: 700;
    line-height: normal !important;
    margin-bottom: 0 !important;
    text-transform: unset !important;
  }

  .widget-subtitle {
    // color: $primary-blueish-gray;
    font-size: 14px !important;
    font-weight: 500;
    line-height: normal !important;
    margin-bottom: 0 !important;
    text-transform: unset !important;
  }

  .ng-dashboard-tile-header {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    &__action {
      margin-top: 0;
      margin-right: 0;
    }
  }

  .ng-dashboard-tile-body {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.widget-actions-wrapper {
  display: flex;
}
/* [Tiles] --- Ends Here */

/* [Toggle Button Group] --- Starts Here */
.ng-toggle-btn-group {
  border: none !important;

  .ng-toggle-btn {
    border-radius: 8px !important;
    border-color: transparent;
    font-size: 14px;
    font-weight: 700;
    // color: $toggle-btn-normal-text;
    text-transform: unset !important;
    margin: 0 6px;
    // padding: 10px !important;
    min-width: 80px;

    .label {
      text-transform: unset !important;
    }

    // &.selected {
    //   background-color: $toggle-btn-selected-fill;
    //   border-color: $toggle-btn-selected-border;
    //   color: $toggle-btn-selected-text;
    // }

    // &:hover {
    //   background-color: $toggle-btn-hover-fill;
    // }
  }
}
/* [Toggle Button Group] --- Ends Here */

/* [Menu and Menu List] --- Starts Here */
.ng-menu-paper {
  // background-color: $popover-menu-fill;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;

  .ng-menu-list {
    width: 100%;
    // background-color: $popover-list-item-fill !important;
    padding: 4px !important;

    li {
      // color: $primary-dark;
      font-size: 14px !important;
      font-weight: 400 !important;
      padding: 11px 7px !important;
      border-radius: 5px !important;

      svg {
        // color: $primary-dark;
      }

      &:not(:last-of-type) {
        margin-bottom: 2px;
      }

      &:hover {
        // background-color: $popover-list-item-hover-fill !important;
      }

      &[aria-selected='true'] {
        // background-color: $popover-list-item-selected-fill !important;
      }
    }
  }
}
/* [Menu and Menu List] --- Ends Here */

/* [Material Table Overrides] --- Starts Here */
.ng-material-table {
  &__header {
    .title {
      // color: $primary-dark;
      font-size: 22px;
      font-weight: 700;
    }

    .sub-title {
      // color: $primary-gray;
      font-size: 12px;
      font-weight: 400;
      margin-top: -20px;
      padding-left: 20px;
    }

    .toolbar {
      padding: 10px 20px;
      min-height: auto !important;

      &.highlight {
        // background-color: #ffffff !important;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .title {
          // color: $primary-gray;
          font-size: 14px;
          font-weight: 400;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .title {
        // color: $primary-dark;
        font-size: 22px;
        font-weight: 700;
      }

      .sub-title {
        // color: $primary-gray;
        font-size: 12px;
        font-weight: 400;
        padding-left: 20px;
        margin-top: -20px;
      }

      .toolbar-search {
        & > div:first-child {
          border-radius: 14px;
          border-color: transparent;
          border-width: 1px;
          border-style: solid;
          // background-color: #ffffff !important;
          padding: 5px 0 5px 10px;
          width: 100%;
          outline: none !important;
          box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 0.2);

          &:hover {
            // background-color: $textbox-focus-bg !important;
          }

          &.Mui-focused,
          &:focus {
            // background-color: $textbox-focus-bg !important;
            // border-color: transparent !important;
            // box-shadow: 0 0 3px -1px rgba(94, 129, 244, 1);
          }

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.ng-mattbl-action-icon {
  color: $primary-gray;
  width: 20px !important;
  height: 20px !important;

  $sizes: 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32;
  $spaces: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

  @each $size in $sizes {
    &.size-#{$size}px {
      width: #{$size}px !important;
      height: #{$size}px !important;
    }
  }

  @each $space in $spaces {
    .space-around-#{$space}px {
      width: $space + px;
      height: $space + px;
    }
  }

  &.success-tick {
    color: $secondary-green;
  }

  &.color-red,
  &.remove-row {
    color: $secondary-red;
  }
}

.MuiTable-root {
  margin-top: 5px;

  // Table Header -> thead
  .MuiTableHead-root {
    .MuiTableCell-head,
    .ng-mattbl-th {
      &:hover {
        // background-color: $mui-table-th-hover-fill !important;
        // border-radius: 10px !important;
      }

      .MuiTableSortLabel {
        color: $mui-table-th-text;

        &-active {
          color: $primary-blue !important;

          svg {
            fill: $primary-blue !important;
          }
        }
      }
    }
  }

  // Table Body - tbody
  .MuiTableBody-root {
    .MuiTableRow-root[index] {
      td {
        // border-bottom-color: $mui-table-row-border-fill !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        // color: $mui-table-cell-text !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }

      &:hover {
        // background-color: $mui-table-row-hover-fill !important;
      }
    }
  }

  // Table Pagination
  .ng-mattbl-pagination {
    padding: 10px;

    span {
      button {
        width: 40px;
        height: 40px;
        background-color: $mui-table-page-btn-fill;
        border-radius: 6px !important;
        margin: 0 3px;

        svg {
          color: $primary-gray;
        }

        &:hover {
          background-color: $mui-table-page-navbtn-hover-fill !important;
        }

        &:disabled {
          background-color: $mui-table-page-btn-disabled-fill;

          svg {
            color: #1c1d2133;
          }
        }
      }
    }
  }

  // Custom Pagination
  .ng-mattbl-cust-pagination {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;

    .ng-pagination-controls {
      li {
        button {
          width: 40px;
          height: 40px;
          background-color: transparent;
          border-radius: 6px !important;
          border: none !important;
          margin: 0 3px;
          color: $primary-dark;
          font-weight: 600;

          // &:hover {
          //   background-color: $mui-table-page-btn-fill;
          // }

          // &.Mui-selected {
          //   background-color: $mui-table-page-btn-active-fill !important;
          //   color: #ffffff;
          // }

          &:disabled {
            cursor: not-allowed;
          }
        }

        &:first-of-type,
        &:last-of-type {
          button {
            // background-color: $mui-table-page-btn-fill !important;

            // svg {
            //   color: $primary-gray;
            // }

            // &:hover {
            //   background-color: $mui-table-page-navbtn-hover-fill !important;
            // }

            &:disabled {
              // background-color: $mui-table-page-btn-disabled-fill;
              opacity: 0.8 !important;

              svg {
                color: #1c1d2133;
              }
            }
          }
        }

        &:first-of-type {
          &::after {
            content: 'Prev';
            display: inline-flex;
            margin: 0 30px 0 10px;
            font-size: 14px;
            font-weight: 400;
            color: $primary-gray;
          }
        }

        &:last-of-type {
          &::before {
            content: 'Next';
            display: inline-flex;
            margin: 0 10px 0 30px;
            font-size: 14px;
            font-weight: 400;
            color: $primary-gray;
          }
        }
      }
    }

    .ng-form-control-select-placeholder {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
}
/* [Material Table Overrides] --- Ends Here */

/* NumericFieldStepper --- Starts Here */
.ng-stepper-control {
  .MuiButton-outlinedPrimary {
    border-width: 1px;
    border-radius: 8px;
    padding-left: 0;
    padding-right: 0;
    min-width: 32px;
    width: 32px;

    &:hover {
    }
  }
  .stepper-left-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none !important;

    &:hover {
      border-right: none;
    }
  }
  .stepper-right-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none !important;

    &:hover {
      border-left: none;
    }
  }

  .ng-form-control-label {
    right: 0;
    transform: translate(0, 10px) scale(1);
    font-size: 12px;
    text-align: center;
  }
  .ng-form-control {
    &.ng-form-control-filled {
      border-width: 1px;
      border-radius: 0;

      &:hover {
      }

      input {
        font-size: 18px;
      }
    }
  }
}
/* NumericFieldStepper --- Ends Here */

.ng-form-control-currency-input {
  input {
    text-align: right;
  }
}
