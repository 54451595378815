/** 
 * @moduleName: CNG App Common
 * @createdDate: 08-aug-2020
 * @author: senthilkumar-r
 * @copyright: GeTS
 */

/* 
 * This stylesheet has style declarations to override MUI and others general styles
*/

@charset "utf-8";

@import './skin';
@import './helpers';
@import './dataSpinner';

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif !important;
}

//  h1,
//  h2,
//  h3,
//  h4,
//  h5,
//  h6,
//  p,
//  label,
//  a,
//  li,
//  span,
//  div,
//  fieldset,
//  legend,
//  button,
//  input {
//    font-family: inherit !important;
//  }

/* [Application Brand Bar] --- Starts Here */
.logo-gets {
  border-radius: 5px;
  margin: 10px 16px 0px 0;
  width: 35px;
  height: 50px;
}

.btn-loggedin-account {
  border: 1px #e4ebf6a2 solid !important;
  border-radius: 10px !important;
  padding: 5px !important;

  &:hover {
    background-color: $btn-useraccount-focus-bg !important;
  }

  &.active {
    background-color: $btn-useraccount-focus-bg !important;
    border-color: $btn-useraccount-active-border-color !important;

    svg {
      fill: $primary-blue;
    }
  }
}

.loggedin-userdetails-wrapper {
  display: inline-flex;
  flex-direction: column;

  .userdetails__item {
    display: inline-flex;
    line-height: 1.2;
  }

  .loggedin-username {
    font-weight: 700;
    font-size: 14px;
  }

  .loggedin-userid {
    font-size: 12px;
    opacity: 0.5;
  }
}

.hamburger-menu {
  position: relative;
  left: -10px;

  svg {
    width: 24px;
    height: 24px;
    color: $primary-gray;
  }

  &.state {
    &-expanded {
      svg {
        color: $primary-dark;
      }
    }
  }
}
/* [Application Brand Bar] --- Ends Here */

/* [Application Sidebar Navigation] --- Starts Here */
@mixin navSelectedBar($posRight) {
  content: '';
  background-color: #5e81f4;
  width: 2px;
  border-radius: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -#{$posRight}; //this refers to parent padding i.e. 5px. Old Value: 16px
}
.ng-sidebar-box {
  padding: 5px !important;

  .group-expanded {
    &::after {
      @include navSelectedBar(5px);
    }
  }

  .nav-depth-0 {
    &.nav-active {
      &::after {
        @include navSelectedBar(5px);
      }
    }
  }
}

.ng-sidebar-list {
  &.ng-sidebar-no-subheader {
    padding: 0 0;
  }

  &.ng-sidebar-has-subheader {
    padding-top: 0;
  }

  .nav-depth-1 {
    padding-left: 10px !important;
    margin-left: 20px !important;
    width: calc(100% - 20px) !important;
  }

  .nav-depth-2 {
    padding-left: 10px !important;
    margin-left: 40px !important;
    width: calc(100% - 40px) !important;
  }

  .nav-depth-1,
  .nav-depth-2 {
    transition: min-width 0.3s ease-in, margin-left 0.3s ease-in;
  }
  .nav-title {
    white-space: nowrap;
    word-wrap: normal;
  }

  .nav-icon {
    overflow: visible;
  }
}

.desktop-nav {
  .ng-sidebar-box {
    &.collapsed {
      .nav-title,
      .toggle-icon {
        // display: none;
        visibility: hidden;
      }

      .nav-depth-1,
      .nav-depth-2 {
        margin-left: 0px !important;
        min-width: 100% !important;
      }

      .nav-icon,
      .nav-short-title {
        margin-left: 8px; // Old Value: 3px
      }
    }
  }

  .sidebar {
    &-collapsed,
    &-expanded {
      transition: width 0.3s ease-in, display 0.4s ease-in;
    }
  }
}
/* [Application Sidebar Navigation] --- Ends Here */

/* [Application Topbar Navigation] --- Starts Here */
.ng-topbar-nav {
.ng-sidebar-list {
  margin: 0 2px;
}
}

.mega-menu-boundbox {
min-height: 200px;
max-height: 300px;
overflow: hidden;
top: 3px !important;
border-radius: 10px;
box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.2);

ul.mega-menu-list {
  margin: 10px 5px 10px 10px;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 200px;
  max-height: 280px;

  li {
    width: 50%;
    position: relative;

    ul {
      position: fixed;
      left: 50%;
      top: 10px;
      height: calc(100% - 18px);
      overflow-x: hidden;
      width: calc(55% - 28px);
      display: none;
      flex-wrap: nowrap;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 10px;

      &::before {
        content: '';
        position: fixed;
        left: 51%;
        display: none;
        height: calc(100% - 10px);
        width: 1px;
        /* background-image: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.1),
          rgba(0, 0, 0, 0.2),
          rgba(255, 255, 255, 0.1)
        ); */
        // box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
      }

      li {
        width: calc(100% - 5px) !important;
        height: auto !important;
      }
    }

    &:focus-within > ul,
    &:hover > ul {
      display: flex !important;
      transform-origin: left;
      animation: flyoutMenu 0.2s ease-out;

      &::before {
        display: block;
        transform-origin: left;
        animation: flyout 0.5s ease-out;
      }
    }

    a,
    button {
      padding: 8px !important;
      border-radius: 8px;
    }
  }
}
}
@keyframes flyoutMenu {
0% {
  opacity: 0;
  transform: scaleX(0);
}
100% {
  opacity: 1;
  transform: scaleX(1);
  overflow-y: auto;
}
}

@keyframes flyout {
0% {
  opacity: 0;
  transform: scaleX(0);
}
100% {
  opacity: 1;
  transform: scaleX(1);
}
}
/* [Application Topbar Navigation] --- Ends Here */

/* [Footer Links] --- Starts Here */
.footer-links-wrapper {
  margin: 0;
  padding: 0;
  display: inline-flex;
  min-height: 40px;
  align-items: center;

  li {
    display: inline-flex;
    position: relative;
    align-items: center;

    &:not(:last-of-type) {
      a,
      a.nav-link {
        margin: 0 10px;
      }

      &::after {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: $label-secondary-color;
        position: absolute;
        right: 0;
      }
    }

    &:last-of-type {
      a,
      a.nav-link {
        margin-left: 10px;
      }
    }

    a,
    a:link,
    a.nav-link,
    a.nav-link:link {
      color: $label-secondary-color;
      font-size: 12px;
      font-weight: 400;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: darken($label-secondary-color, 30%);
      }
    }
  }
}
/* [Footer Links] --- Ends Here */

/* [Override Material Components] --- Starts Here */
.MuiPickersCalendarHeader-dayLabel {
  font-size: 16px !important;
  font-weight: 900 !important;
}
/* [Override Material Components] --- Ends Here */

/* [Override Browser Scrollbar] --- Starts Here */
.ng-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #f5f5f5;
}
.ng-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f0f1f5;
}
.ng-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #8996af;
}
/* [Override Browser Scrollbar] --- Ends Here */
