/** 
 * @moduleName: login
 * @createdDate: 08-aug-2020
 * @author: senthilkumar-r
 * @copyright: GeTS
 */

/* 
 * This stylesheet has style declarations to rotate the theme
 */

@charset "utf-8";

// Theme 1
.ng-login-theme1 {
  background-image:url('../img/hero/theme1-bg.png');
  align-items: center;
  .btn-go-back {
    color: $white;

    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .ng-login-card-media {
    background-image:url('../img/hero/theme1-bg.png');
  }
}

// Theme 2
.ng-login-theme2 {
  background-image: url('../img/hero/theme2-bg.png');
  align-items: center;
  .btn-go-back {
    color: $label-primary-color;

    &:hover {
      color: rgba(28, 29, 33, 0.5);
    }
  }

  .ng-login-card-media {
    background-image: url('../img/hero/theme2-card-image.png');
  }
}

// Theme 3
.ng-login-theme3 {
  background-image: url('../img/hero/theme3-bg.png');
  align-items: center;
  .btn-go-back {
    color: $label-primary-color;

    &:hover {
      color: rgba(28, 29, 33, 0.5);
    }
  }

  .ng-login-card-media {
    background-image: url('../img/hero/theme3-card-image.png');
  }
}
