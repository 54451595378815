.ls_ng-button-text {
  background-color: none !important;
  box-shadow: none !important;
  border: 0px !important;
  border-radius: 8px !important;
  color: gray !important;
  text-transform: none !important;

  &:hover {
    background-color: $btn-secondary-state-hover !important;
    border-color: $btn-secondary-state-hover !important;
  }

  &:focus {
    background-color: $btn-secondary-state-focus !important;
    border-color: $btn-secondary-state-focus !important;
  }

  &:active {
    background-color: $btn-secondary-state-active !important;
    border-color: $btn-secondary-state-active !important;
  }

  &:disabled {
    background-color: $light-gray-bg !important;
    border-color: $light-gray-bg !important;
    box-shadow: none !important;
    color: $gray-overlay !important;
  }

  &.link-button {
    padding: 6px 13px !important;

    &:hover {
      text-decoration: none !important;
    }
  }
}