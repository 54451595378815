/** 
 * @moduleName: dashboard
 * @createdDate: 08-aug-2020
 * @author: senthilkumar-r
 * @copyright: GeTS
 */

/* 
 * This stylesheet has style declarations for dashboard layout
 */

@charset "utf-8";

@import "./skin";
@import "./helpers";

.ng-content-main-wrapper {
    .ng-content-main-inner {
        padding-top: 10px;

        .ng-content-boundbox {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}

