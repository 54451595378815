/* 
 * This stylesheet is to cover styles in the following modules:
 *      - Company Preference 
 *      - Address Book
 */

.ab-table-cell-header {
    padding: 1em 16px !important;
    color: #8181a5 !important;
    background-color: #F5F5FA65;
    font-size: x-small !important;
    font-weight: bold !important;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ab-table-cell-row {
    border-bottom: none !important;
    border-top: none !important;
    background-color: #ffffff;
    word-break: break-all;
}

.ab-table-cell-padding {
    padding: 0 16px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ab-item-dropdown {
    border-radius: 1em;
    border: 1px solid #5e81f4;
}

.ab-text-green {
    color: rgb(148, 235, 187);
}
.ab-text-red {
    color: #ff808b;
}

.ab-bg-inactive {
    background-color: #ffe8e9;
    border-radius: 5px;
}

.ab-bg-active {
    background-color: #e2ffe7;
    border-radius: 5px;
}

.card-content-label {
    color: #8e98b0 !important;
}

.bold-text-paragraph {
font-weight: bold !important;
justify-content: flex-start;
align-items: center;
}

.modal-sticky-top {
    position: sticky;
    top: 0;
}

.modal-sticky-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1;
}

.modal-content {
    height: 45vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
}

.div-flex {
    display: flex !important;
    width: 100%;
}

.grid-text-ellipsis {
    padding-top: 1vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
    height: 5vh;
    width: 80vw;
}

.contact-box {
    display: flex;
    background-color: #FCFDFF;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    width: 100%;
}

.contact-box-selected {
    display: flex;
    background-color: #FCFDFF;
    border: 1px solid #5e81f4;
    border-radius: 8px;
    width: 100%;
}

.table-card-enclosing {
    display: flex;
    width: 100%;
    border-radius: 8px;
    background-color: #F5F5FA65 !important;
}
