/** 
 * @moduleName: FTA Calculator
 * @createdDate: 21-Nov-2022
 * @author: senthilkumar-r
 * @copyright: GeTS
 */

/* 
 * This stylesheet has application specific global styles
 *  such as skeleton and bootstrap
 */

@charset "utf-8";

/**
 * Page and section skeleton using grid layout
 */
.page-grid {
    display: grid;
    grid-template-areas:
        "header"
        "main";
    row-gap: 32px;
    width: 100%;

    &:not(.child-of-grid) {
        padding: 20px;
    }

    &.edge-to-edge {
        padding: 0 !important;
    }

    &.no-row-gap {
      row-gap: unset;
    }

    &__col {
        display: grid;
    }
}

.section-grid {
    display: grid;
    grid-template-areas:
        "header"
        "main";
    margin-block-end: 32px;
    row-gap: 20px;
    width: 100%;
    
    &.row-gap-0 {
      row-gap: 0;
    }
    
    &:not(.child-of-grid) {
        padding: 20px;
    }

    &__col {
        display: grid;

        &.has-more-childs {
          row-gap: 16px;  
        }
    }
}

.cta-footerbar {
    display: grid;
    width: 100%;
}

.one-col {
    display: grid;
    grid-template-columns: 1fr;
    align-items: baseline;
}

.two-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    align-items: baseline;
}

.two-col-one-auto {
    display: grid;
    grid-template-columns: 1fr minmax(90px, auto);
    align-items: baseline;
}

.three-col {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    align-items: baseline;
}

.four-col {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;
    align-items: baseline;
}

/**
 * Component: Footer button
 * Page: FTA Calculator
 */
.cta-btn-wrapper {
    display: flex;
    width: 100%;
  
    &.flex-end {
      justify-content: flex-end;
    }
  
    .form-control__item, button {
      // &:not(:last-of-type) {
        margin-inline-start: 8px;
      // }
  
      &:only-child {
        margin-inline-start: 0;
      }
    }
}
  
.pre-adornment-icon {
  margin-inline-end: 6px;
}

.post-adornment-icon {
  margin-inline-start: 6px;
}

/**
 * Page: Details content pane
 */
.cng-details-page {
  
  &__content-container {
  // background-color: #fcfcfc;
    border-radius: inherit;

    &.edge-to-edge {
        padding: 0 !important;
    }
  }
  
  &__section:not(:last-child) {
    padding-block-end: 16px;
    
    .cng-grid-container:not(:last-child) {
      padding-block-end: 16px;
    }
  }
}

.section-view-fta-details {
  display: grid;
  align-items: center;

  &.header {
    &.one-col {
      grid-template-columns: 1fr;
      column-gap: 12px;
    }

    &.two-col-minmax-180 {
      grid-template-columns: minmax(90px, 100px) 3fr;
      column-gap: 12px;
    }
  }
}

/**
 * Helpers
 * Page: Summary screens i.e. eCommerce
 */
 .form-label-colon {
   &::after {
     // content: ':';
     content: attr(data-label-suffix);
     font-size: inherit;
     color: inherit;
     display: inline-block;
 
     margin-right: 3px;
     margin-inline-end: 3px;
   }
 }
 
 .field-label {
   color: $form-label-text-color-light;
   font-size: 12px !important;
   font-weight: 400;
 }
 
 .field-value {
   &.light {
     color: $form-value-text-color-light;
   }
 
   &.dark {
     color: $form-value-text-color-dark;
   }
 
   font-size: 14px !important;
   font-weight: 400;
 }
 
 .field-country-flag {
   width: 20px;
   height: 20px;
   border-radius: 50%;
 
   margin-right: 4px;
   margin-inline-end: 4px;
 }
 
 .field-country-name {
   color: $form-value-text-color-dark;
   font-size: 16px !important;
   font-weight: 500;
 }
 
 .field-country-code {
   color: $form-value-text-color-dark;
   font-size: 10px;
   font-weight: 400;
   text-transform: uppercase;
   background-color: $country-code-chip-bg-fill !important;
   border: 1px $country-code-chip-border-color solid !important;
   min-height: 18px;
   box-sizing: border-box;
 
   padding-left: 6px;
   padding-right: 6px;
   padding-inline: 6px;
 
   padding-top: 3px;
   padding-bottom: 3px;
   padding-block: 3px;
 }
 
 .field-vertical {
  &__label-value  {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
    margin-bottom: 8px;
    margin-block-end: 8px;
  }
 
  &__item {
     width: 100%;
     display: inline-flex;
    //  flex: 1;

    &.justify-start {
        justify-content: flex-start;
    }
    
    &.justify-center {
        justify-content: center;
    }

    &.justify-end {
        justify-content: flex-end;
    }
 
     &:first-of-type {
       // margin-bottom: 5px;
       // margin-block-end: 5px;
     }
 
     label {
       margin: 0;
       display: inline-block;

       &.break-word {
        word-break: break-word;
        margin-inline-end: 12px;
       }
     }

    .pre-adornment-icon {
      margin-inline-end: 6px;
    }
  
    .post-adornment-icon {
      margin-inline-start: 6px;
    }
   }
}

// Min height
@for $i from 100 through 300 {
    .mh-#{$i} {
        min-height: #{$i}px;
    } 
}

/**
 * Component: tabs button, filter panel, summary cards etc.
 * Page: Summary screens i.e. eCommerce
 */

 // Summary cards
 .summary-list-items--container {
   border: none;
   box-shadow: none;
 
   .summary-list-item--card {
     border: 1px $summary-card-border solid;
     border-radius: $summary-card-rounded-radius;
     box-shadow: none;
 
     margin-bottom: 14px;
     margin-block-end: 14px;
   }
 }
 
 .summary-list-item--card {
   position: relative;
   transition: background-color 0.2s ease-in, box-shadow 0.2s ease-in;
 
   &:hover {
     background-color: rgba(240, 240, 240, 0.147);
     box-shadow: 0 1px 0 -1px rgba(63, 63, 68, 0.1),
       0 1px 2px 0 rgba(63, 63, 68, 0.2);
   }
 
   // Card header
   .cng-summary-card--header-box {
     background-color: $summary-card-header-bg;
     flex: 1;
     width: calc(100% - 4px);
     height: 44px;
     margin: 2px;
     border-radius: 10px;
 
     padding-top: 0;
     padding-bottom: 0;
     padding-block: 0;
 
     padding-left: 10px;
     padding-right: 4px;
     padding-inline: 10px 4px;
 
     position: relative;
     z-index: 1;
 
     .cng-summary-card--header-box--left,
     .cng-summary-card--header-box--right {
       height: 100%;
       display: flex;
       align-items: center;
       justify-content: flex-start;
     }
     
     .cng-summary-card--header-box--left {
      .header-box--left-col-1,
      .header-box--left-col-2 {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .header-box--left-col-1 {
        flex: 1;
      }
     }

     .cng-summary-card--header-box--right {
       .header-box--right-col-1,
       .header-box--right-col-2 {
         height: 100%;
         display: flex;
         align-items: center;
         justify-content: flex-start;
       }
 
       .header-box--right-col-1 {
         flex: 1;
       }
 
       .header-box--right-col-2 {
         flex-wrap: nowrap;
 
         &.cta-box {
           min-width: 36px;
           height: 100%;
         }
 
         .cng-summary-card--more-actions-wrapper {
           // margin: 4px;
 
           button {
             width: 36px !important;
             height: 36px !important;
             min-height: auto !important;
             background-color: #f5f5f5 !important;
 
             svg {
               font-size: 18px;
               font-weight: 200;
             }
           }
         }
       }
     }
 
     .field-vertical--label-value {
       margin-bottom: 0px;
       margin-block-end: 0px;
 
       .field-vertical--item {
         &:first-of-type {
           margin-bottom: -3px;
           margin-block-end: -3px;
         }
       }
     }

     .box-col {
        &:only-child {
          margin-inline-end: 10px;
        }

        &:not(:last-of-type) {
          margin-inline-end: 8px;
        }
     }
   }
 
   // Card body
   .cng-summary-card--body-box {
     .MuiPaper-rounded {
       border-radius: 0;
     }
 
     .cng-summary-card--body-grid {
       padding-left: 0;
       padding-right: 0;
       padding-inline: 0;
 
       padding-top: 15px;
       padding-bottom: 15px;
       padding-block: 15px;
     }
 
     [class*='focusHighlight'] {
       display: none;
     }
 
     &:hover {
       // background-color: rgba(240, 240, 240, 0.147) !important;
 
       [class*='focusHighlight'] {
         display: block;
         background-color: rgba(240, 240, 240, 0.147) !important;
       }
 
       [class*='TouchRipple'] {
         display: none;
       }
     }
   }
 
   // Card footer
   .cng-summary-card--footer-box {
     flex: 1;
     width: calc(100% - 4px);
     height: 36px;
     margin: 2px;
     border-radius: 10px;
 
     padding-top: 0;
     padding-bottom: 0;
     padding-block: 0;
 
     padding-left: 10px;
     padding-right: 10px;
     padding-inline: 10px;
 
     position: relative;
     z-index: 1;
 
     .cng-summary-card--footer-box--left,
     .cng-summary-card--footer-box--right {
       height: 100%;
       display: flex;
       align-items: center;
       justify-content: flex-start;
     }
 
     .cng-summary-card--footer-box--left {
       span {
         &:first-of-type {
           margin-right: 5px;
           margin-inline-end: 5px;
         }
         &:not(:first-of-type) {
           margin-right: 8px;
           margin-inline-end: 8px;
         }
 
         label {
           margin-bottom: 0;
           margin-block-end: 0;
 
           &:nth-child(2) {
             font-weight: 500;
           }
         }
       }
     }
 
     .cng-summary-card--footer-box--right {
       .footer-box--right-col-1,
       .footer-box--right-col-2 {
         height: 100%;
         display: flex;
         align-items: center;
         justify-content: flex-start;
       }
 
       .footer-box--right-col-1 {
         flex: 1;
       }
 
       .footer-box--right-col-2 {
         flex-wrap: nowrap;
 
         &.cta-box {
           min-width: 36px;
           height: 100%;
         }
 
         .cng-summary-card--more-actions-wrapper {
           // margin: 4px;
 
           button {
             width: 36px !important;
             height: 36px !important;
             min-height: auto !important;
             background-color: #f5f5f5 !important;
             margin-inline-start: 8px;
 
             svg {
               font-size: 18px;
               font-weight: 200;
             }
           }
         }
       }
     }
   }
 }

// Fields in cards
.cng-summary-card--country-value {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-block-end: 8px;
  overflow: hidden;
}

.field-country-details {
 display: flex;
 flex-wrap: wrap;
 align-items: center;
 justify-content: flex-start;
 min-height: 20px;

 .field-country-name {
   margin-inline-end: 12px;
 }

 .field-country-code {
   margin-inline: 5px 10px;
   margin-block: 5px;
 }
}

// This horizontal field alignment is used especially for switch field
 .field-horizontal{
  &__label-value {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
 }
}
 
 .flex-start {
   justify-content: flex-start !important;
 }
 
 .flex-end {
   justify-content: flex-end !important;
 }

 .align-items-baseline {
  align-items: baseline;
 }

 .align-items-center {
  align-items: center;
 }
 
 // Font sizes
 @for $i from 1 through $max-font-size {
   .font-#{$i} {
     font-size: $i + px !important;
   }
 }

 .clamp-line-to-one {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.clamp-line-to-two {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.clamp-line-to-three {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

// Summary screen header
.cng-summary-header {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  gap: 10px;

  &__col {
    display: flex;
    align-items: center;

    &.auto {
      width: auto;
      flex: 1;
    }

    &.cta-46 {
      width: 46px;
      height: 46px;
    }
  }
}

/**
 * Component: showing records count label
 * Page: Summary screens
 */
 .summary-count-label-wrapper {
  display: inline-flex;
  min-height: 40px;
  align-items: center;

  label {
    color: $primary-gray;
    font-size: 14px;
    margin: 0;
  }
}

/**
 * Component: No reults
 * Page: Summary screens
 */
 .fta-no-results--wrapper {
  display: flex;
  flex-direction: column;
  padding-inline: 10px;
  padding-block-start: 50px !important;
  padding-block-end: 20px !important;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.fta-no-results--action-wrapper {
  display: grid;
  align-items: center;
  column-gap: 8px;
  width: 100%;

  @each $size in $col-sizes {
    &.col-#{$size} {
      grid-template-columns: repeat(#{$size}, auto);
    }
  }
}